import { createAsyncThunk } from '@reduxjs/toolkit';
import contractsService from 'services/contractsService';
import { RequestParams } from 'utils/mapParams';
import { CommonRequestParams } from 'models/dto/params.dto';
import { CreateContractParams, UpdateContractParams } from 'models/interfaces/createContract.interface';
import { ContractDetailsInterface } from 'models/dto/contracts.dto';
import { enqueueSnackbar } from 'store/notifications';
import { NotificationType } from 'services/notificationService';

export const getContracts = createAsyncThunk(
  'contracts/getContracts',
  async (data: { params: Partial<RequestParams>; id?: number }, thunkApi) => {
    try {
      const res = await contractsService.getContracts(data.params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkApi.rejectWithValue(err.message.original);
    }
  }
);

export const getContractById = createAsyncThunk(
  'contracts/getContractById',
  async (data: { id: number; params?: CommonRequestParams }, thunkAPI) => {
    try {
      const contract: ContractDetailsInterface = await contractsService.getContractById(data);

      return contract;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const createContract = createAsyncThunk(
  'contracts/createContract',
  async (params: CreateContractParams, thunkAPI) => {
    const { values, callback } = params;
    try {
      const res = await contractsService.createContract(values);
      callback();
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Contract created successfully!',
            title: 'Contract',
          },
        })
      );
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const updateContract = createAsyncThunk(
  'contracts/updateContract',
  async (params: UpdateContractParams, thunkAPI) => {
    const { id, values } = params;
    try {
      const res = await contractsService.updateContract({ id, values });
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Contract Details updated successfully!',
            title: 'Contract Details',
          },
        })
      );
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);