import { createAsyncThunk } from '@reduxjs/toolkit';
import engagementModelService from 'services/engagementModelService';
import { RequestParams } from 'utils/mapParams';

export const getEngagementModelList = createAsyncThunk(
  'engagementModel/getEngagementModelList',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const res = await engagementModelService.getEngagementModelList(params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
