import { MainNavInterface } from '../models/interfaces/mainNavInterface';
import { ALL_PERMISSIONS, SALES_PERMISSIONS, Role } from './roles';

export const MAIN_MENU: MainNavInterface[] = [
  {
    icon: 'pe-7s-users',
    permissions: ALL_PERMISSIONS,
    label: 'Company',
    content: [
      {
        label: 'Structure',
        to: '#/dashboard/company_structure',
        permissions: ALL_PERMISSIONS,
      },
      {
        label: 'Holidays',
        to: '#/dashboard/holidays',
        permissions: ALL_PERMISSIONS,
      },
      {
        label: 'Absence Calendar',
        to: '#/dashboard/absence_calendar',
        permissions: ALL_PERMISSIONS,
      },
    ],
  },
  {
    icon: 'pe-7s-credit',
    permissions: [Role.vp, Role.hr, Role.finance],
    label: 'Finance',
    content: [
      {
        label: 'Workforce Costs',
        to: '#/dashboard/workforce_costs',
        permissions: [Role.vp, Role.finance],
      },
      {
        label: 'Company Expenses',
        to: `#/dashboard/company_expenses`,
        permissions: [Role.vp, Role.hr, Role.finance],
      },
      {
        label: 'Compensation Orders',
        to: '#/dashboard/compensation_orders',
        permissions: [Role.vp, Role.finance],
      },
    ],
  },
  {
    icon: 'pe-7s-stopwatch',
    permissions: [Role.vp, Role.finance, Role.teamlead, Role.pm, Role.delivery, Role.sales],
    label: 'Project Management',
    content: [
      {
        label: 'Resource Requests',
        to: '#/dashboard/resource_requests',
        permissions: [Role.vp, Role.teamlead, Role.finance, Role.pm, Role.delivery, Role.sales],
      },
      {
        label: 'Overtime Requests',
        to: '#/dashboard/overtime_requests',
        permissions: [Role.vp, Role.teamlead, Role.finance, Role.pm, Role.delivery],
      },
      {
        label: 'Project Calendar',
        to: '#/dashboard/project_calendar',
        permissions: [Role.vp, Role.teamlead, Role.finance, Role.pm, Role.delivery],
      },
    ],
  },
  {
    icon: 'pe-7s-monitor',
    permissions: [Role.vp, Role.hr],
    label: 'Human Resources',
    content: [
      {
        label: 'Employees',
        to: '#/dashboard/employees',
        permissions: [Role.vp, Role.hr],
      },
      {
        label: 'Performance Review',
        to: '#/dashboard/performance_review',
        permissions: [Role.vp, Role.hr],
      },
      {
        label: 'Assignees',
        to: '#/dashboard/assignees',
        permissions: [Role.vp, Role.hr],
      },
    ],
  },
  {
    icon: 'pe-7s-user',
    permissions: [Role.vp, Role.teamlead, Role.finance],
    label: 'Team Lead',
    content: [
      {
        label: 'Team Profile',
        to: '#/dashboard/team_lead_workforce',
        permissions: [Role.vp, Role.teamlead],
      },
      {
        label: 'Mentors Assignment',
        to: '#/dashboard/mentors_assignment',
        permissions: [Role.vp, Role.teamlead],
      },
      {
        label: 'Worklog Issues',
        to: '#/dashboard/worklog_issues',
        permissions: ALL_PERMISSIONS,
      },
      {
        label: 'Team Budget',
        to: '#/dashboard/team_budget',
        permissions: [Role.vp, Role.teamlead, Role.finance],
      },
    ],
  },
  {
    icon: 'pe-7s-hammer',
    permissions: SALES_PERMISSIONS,
    label: 'Legal',
    content: [
      {
        label: 'Contracts',
        to: '#/dashboard/contracts',
        permissions: SALES_PERMISSIONS,
      },
    ],
  },
  {
    icon: 'pe-7s-rocket',
    permissions: [Role.vp, Role.leadgen, Role.sales, Role.delivery],
    label: 'Business Development',
    content: [
      {
        label: 'Workforce Availability',
        to: '#/dashboard/availability',
        permissions: [Role.vp, Role.leadgen, Role.sales, Role.delivery],
      },
      {
        label: 'Deals',
        to: '#/dashboard/deals',
        permissions: [Role.vp, Role.sales, Role.leadgen, Role.delivery],
      },
      {
        label: 'Rate Card',
        to: '#/dashboard/rate_card',
        permissions: [Role.vp],
      },
      {
        label: 'BDM Funnel',
        to: '#/dashboard/outstuff_funnel',
        permissions: [Role.vp, Role.leadgen, Role.sales, Role.delivery],
      },
      {
        label: 'Totals',
        to: '#/dashboard/totals',
        permissions: [Role.vp],
      },
    ],
  },
  {
    icon: 'pe-7s-ribbon',
    permissions: [Role.vp, Role.teamlead, Role.sales, Role.leadgen, Role.delivery, Role.pm],
    label: 'Workload',
    content: [
      {
        label: 'Available Hours',
        to: '#/dashboard/workforse_hours',
        permissions: [Role.vp, Role.teamlead, Role.leadgen, Role.sales, Role.delivery, Role.pm],
      },
      {
        label: 'Workload Calendar',
        to: '#/dashboard/workload_calendar',
        permissions: [Role.vp, Role.teamlead],
      },
      {
        label: 'External Interviews',
        to: '#/dashboard/external_interviews',
        permissions: [Role.vp, Role.teamlead, Role.leadgen, Role.sales, Role.delivery, Role.pm],
      },
    ],
  },
  {
    icon: 'pe-7s-notebook',
    label: 'Profile',
    to: '#/dashboard/profile',
    permissions: ALL_PERMISSIONS,
  },
];
