import PageTitle from '../../Layout/AppMain/PageTitle';
import Contracts from 'components/ContractsDashboard/Contracts';
import { contractsIcon } from '../../constants/icons';

const ContractsPage = () => {
  return (
    <>
      <PageTitle heading="Contracts" icon={contractsIcon} />
      <Contracts />
    </>
  );
};

export default ContractsPage;
