import { createSlice } from '@reduxjs/toolkit';
import { getBusinessPaymentList } from './businessPayments.thunk';
import { BusinessPaymentsInitialState } from 'models/interfaces/businessPayments.interface';

const initialState: BusinessPaymentsInitialState = {
  payments: null,
  loading: false,
  error: null,
};

const businessPaymentsSlice = createSlice({
  name: 'businessPipelines',
  initialState,
  reducers: {},
  extraReducers: {
    [getBusinessPaymentList.pending.toString()]: state => {
      state.payments = null;
      state.loading = true;
      state.error = null;
    },
    [getBusinessPaymentList.fulfilled.toString()]: (state, { payload }) => {
      state.payments = payload.items;
      state.loading = false;
    },
    [getBusinessPaymentList.rejected.toString()]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export default businessPaymentsSlice.reducer;
