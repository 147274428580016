import httpService, { HttpService } from './httpService';
import { RequestParams } from 'utils/mapParams';
import { createBusinessProject } from 'models/interfaces/businessProjects.interface';

export class BusinessProjectsService {
  constructor(private _httpService: HttpService) {}

  async getBusinessProjectsList(params: Partial<RequestParams>) {
    const res = this._httpService.get('/business/projects', { queryStringParameters: params });
    return res;
  }

  async createBusinessProject(params: createBusinessProject) {
    const res = this._httpService.post('/business/projects', { body: params });
    return res;
  }
}

const businessProjectsService = new BusinessProjectsService(httpService);

export default businessProjectsService;
