import React, { useState } from 'react';
import { Table } from 'reactstrap';
import cx from 'classnames';
import { format } from 'date-fns';
import EditServiceModal from 'common/Modals/CreateContractModal/EditServiceModal';
import { Service } from 'models/interfaces/services.interface';
import styles from '../../tableStyles.module.scss';

type TProps = {
  data: Array<Service>;
  callback: () => void;
  totals: { amount: number };
  isEditable: boolean;
  editContract: any;
};

const ServicesTable: React.FC<TProps> = ({ data = [], callback, totals, isEditable, editContract }) => {
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [openEditServiceModal, setOpenEditServiceModal] = useState<boolean>(false);

  const handleRowClick = (service: Service) => {
    setSelectedService(service);
    setOpenEditServiceModal(true);
  };

  return (
    <>
      <div className={styles.tableHeading}>Services</div>
      <div className={styles.tableWrapper}>
        <Table
          responsive
          hover
          className={cx(styles.tableContainer, styles.minWidth1400, 'align-middle mb-0 custom-table text-left')}
        >
          <thead>
            <tr>
              <th className={styles.firstCellWidth}>#</th>
              <th>Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Service Type</th>
              <th>Billing Type</th>
              <th>Currency</th>
              <th>Amount</th>
              <th>Quantity</th>
              <th>Discount</th>
              <th>Total Amount</th>
            </tr>
          </thead>

          <tbody>
            {data?.map((item, index: number) => (
              <tr key={index} className={styles.removeBorder}>
                <td>{index + 1}</td>
                <td style={{ cursor: 'pointer', color: '#4F75FF' }} onClick={() => handleRowClick(item)}>
                  {item?.name || '-'}
                </td>
                <td>{format(new Date(item?.startDate), 'yyyy/MM/dd') || '-'}</td>
                <td>{format(new Date(item?.endDate), 'yyyy/MM/dd') || '-'}</td>
                <td>{item?.serviceType || '-'}</td>
                <td>{item?.billingType?.name || '-'}</td>
                <td>{item?.currency || '-'}</td>
                <td>{item?.amount || '-'}</td>
                <td>{item?.quantity || '-'}</td>
                <td>{item?.discount || '-'}</td>
                <td>{item?.total || '-'}</td>
              </tr>
            ))}
            <tr className={styles.totalRow}>
              <td colSpan={10} className={styles.tableBottom}>
                Total
              </td>
              <td style={{ height: 40 }}>${totals?.amount}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      {openEditServiceModal && (
        <EditServiceModal
          onClose={() => setOpenEditServiceModal(false)}
          callback={callback}
          setOpenModal={setOpenEditServiceModal}
          currentService={selectedService ? selectedService : null}
          editContract={editContract}
          isEditable={isEditable}
        />
      )}
    </>
  );
};

export default ServicesTable;
