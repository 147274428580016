import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteService, getServices, updateService } from './services.thunk';
import { ServicesState } from 'models/interfaces/services.interface';

const initialState: ServicesState = {
  items: [],
  total: 0,
  totals: { amount: 0},
  pages: 0,
  loading: false,
  error: null,
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    clearServices: () => ({
      ...initialState,
    }),
    updateServiceLocally: (state, action: PayloadAction<{ id: number; name: string; value: any }>) => {
      state.items = state.items.map(item =>
        item.id === action.payload.id ? { ...item, [action.payload.name]: action.payload.value } : item
      );
    },
  },
  extraReducers: {
    [getServices.pending.toString()]: state => {
      state.items = [];
      state.loading = true;
      state.error = null;
    },
    [getServices.fulfilled.toString()]: (state, action) => {
      state.loading = false;

      if (action.meta.arg?.id) {
        state.items = {
          ...state.items,
          [action.meta.arg.id]: action.payload.items,
        };
        state.totals = {
          ...state.totals,
          [action.meta.arg.id]: action.payload.totals,
        };
      } else {
        state.items = action.payload.items;
        state.totals = action.payload.totals;
        state.total = action.payload.total;
        state.pages = action.payload.pages;
      }
    },
    [getServices.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [updateService.pending.toString()]: state => {
      state.loading = true;
    },
    [updateService.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.items = state.items.map(service =>
        service.id === action.payload.id ? { ...service, ...action.payload } : service
      );
      state.totals = action.payload.totals;
    },
    [updateService.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deleteService.pending.toString()]: state => {
      state.loading = true;
    },
    [deleteService.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.items = state.items.filter(service => service.id !== action.payload);
      state.totals = action.payload.totals;
    },
    [deleteService.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { clearServices, updateServiceLocally } = servicesSlice.actions;
export default servicesSlice.reducer;
