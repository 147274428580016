import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import { DropdownList } from 'react-widgets';

import { AppState } from 'store/configureStore';
import { getBusinessPipelinesList } from 'store/businessPipelines/businessPipelines.thunk';
import { useQuery } from 'hooks/queryHook';
import { formatBisinessPipelines } from 'utils/formatDatas';
import { BusinessPipelines } from 'models/interfaces/businessPipelines.interface';

import styles from '../../components/tableStyles.module.scss';

interface BusinessPipelinesFilterProps {
  setFilterValue: (value: string, search?: string) => void;
  searchValue?: string;
}

const BusinessPipelinesFilter = ({ setFilterValue, searchValue }: BusinessPipelinesFilterProps) => {
  const dispatch = useDispatch();

  const { pipelines, loading } = useSelector((state: AppState) => state.businessPipelinesReducer);
  const { pipelineId } = useQuery();
  const [selectedPipeline, setSelectedPipeline] = useState<BusinessPipelines | null>(null);

  useEffect(() => {
    dispatch(getBusinessPipelinesList({ page: 1, size: 50 }));
  }, []);

  useEffect(() => {
    if (pipelines && pipelineId) {
      const selected = pipelines.find((pipeline: BusinessPipelines) => pipeline.id.toString() === pipelineId);
      if (selected) {
        setSelectedPipeline(selected);
      }
    }
  }, [pipelines, pipelineId]);

  const onPipelineChange = (pipeline: BusinessPipelines) => {
    setFilterValue(pipeline.id, searchValue);
    setSelectedPipeline(pipeline);
  };

  const onPipelineSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      const target = event.target as HTMLInputElement;
      dispatch(getBusinessPipelinesList({ page: 1, size: 50, name: target.value }));
    }, 250),
    []
  );

  const pipelinesList = formatBisinessPipelines(pipelines || []);

  return (
    <div className="dropdown-filter">
      <p className="label-wrapper">Pipeline</p>
      <DropdownList
        containerClassName={cx(styles.inputsField, styles.dropdown)}
        value={selectedPipeline || ''}
        placeholder="All"
        data={pipelinesList}
        textField="name"
        filter="contains"
        onChange={onPipelineChange}
        onKeyDown={onPipelineSearch}
        busy={loading}
      />
    </div>
  );
};

export default BusinessPipelinesFilter;
