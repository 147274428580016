import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, matchPath, useParams } from 'react-router-dom';

import { AppState } from 'store/configureStore';
import { mappedProfile } from 'store/Profile/mappedProfile.selector';
import routes from 'config/routes';
import { ParamsT } from 'models/interfaces/employeeProfileProps';

import styles from './navigationStyle.module.scss';

interface NavigationItem {
  pathname: string;
  search: string;
  title: string;
}

interface NavigationItems {
  navigationItems: NavigationItem[];
  dropdownItems: NavigationItem[];
}

interface NavigationValues {
  dropdownValues: NavigationItem[];
  navigationHistoryValues: NavigationItem[];
}

const NavigationHistory: FC = () => {
  const location = useLocation();
  const { id } = useParams<ParamsT>();

  const { team } = useSelector((state: AppState) => state.companyStructureReducer);
  const { deal } = useSelector((state: AppState) => state.bdmReducer);
  const { contract } = useSelector((state: AppState) => state.contractsReducer);
  const { selectedCycle } = useSelector((state: AppState) => state.performanceReviewReducer);
  const user = useSelector(mappedProfile);

  const { userId } = useSelector((state: AppState) => state.account?.user);
  const employeeId: string = id ? id : userId;

  const divRef = useRef<HTMLDivElement>(null);

  const data: string | null = sessionStorage.getItem('navigation');
  let navigationItems: NavigationItem[] | null = JSON.parse(data as string);

  const [open, setOpen] = useState<boolean>(false);

  const letterWidth = 6.5;
  const navigationWidth = divRef.current?.offsetWidth;

  const getNavigationItems = (navigation: NavigationItem[] | null) => {
    const dropdownValues: NavigationItem[] = [];
    let navigationHistoryValues: NavigationItem[] = [];

    const getNavigationValues = (navigation: NavigationItem[] | null): NavigationValues | void => {
      if (!navigation) {
        return { dropdownValues: [], navigationHistoryValues: [] };
      }

      const arr = [...navigation];

      const getItemsWidth = (navigationItems: NavigationItem[] | null) => {
        return navigationItems?.reduce(
          (sum, item, index) =>
            sum +
            (item.title.length * letterWidth < 150 ? item.title.length * letterWidth : 150) +
            (index + 1 !== navigationItems?.length ? 34 : 0) +
            (!dropdownValues?.length ? 0 : 29),
          32
        );
      };

      const itemsWidth = getItemsWidth(navigation);

      if (itemsWidth && navigationWidth && itemsWidth > navigationWidth) {
        const item = arr.splice(-2, 1);
        dropdownValues.unshift(...item);
        navigationHistoryValues = arr;
        return getNavigationValues(arr);
      } else {
        navigationHistoryValues = arr;
      }
    };
    getNavigationValues(navigation);

    if (dropdownValues.length) {
      navigationHistoryValues.splice(-1, 0, { title: '...', pathname: '', search: '' });
    }

    return { dropdownValues, navigationHistoryValues };
  };

  const items = getNavigationItems(navigationItems);

  const [navigationHistory, setNavigationHistory] = useState<NavigationItems>({
    navigationItems: items.navigationHistoryValues,
    dropdownItems: items.dropdownValues,
  });

  const getNavigationTitle = useCallback(
    (title?: string) => {
      if (title === 'TeamProfile') {
        return team?.title || null;
      } else if (title === 'DealDetails') {
        return deal?.name || null;
      } else if (title === 'ContractDetails') {
        return contract?.name || null;
      } else if (title === 'PerformanceReviewDetails') {
        return selectedCycle?.title || null;
      } else if (title === 'Profile') {
        return user?.id === +employeeId ? `${user?.firstName} ${user?.lastName}` : null;
      } else {
        return title;
      }
    },
    [user, team, deal, selectedCycle, contract, employeeId]
  );

  useEffect(() => {
    const data: string | null = sessionStorage.getItem('navigation');
    let navigationItems: NavigationItem[] | null = JSON.parse(data as string);

    const route = routes.find(route => !!matchPath(location.pathname, route.path));

    const title: string = getNavigationTitle(route?.navigationTitle);

    if (title === null) {
      return;
    }

    if (!navigationItems) {
      const currentPath = JSON.stringify([{ pathname: location.pathname, search: location.search, title: title }]);
      sessionStorage.setItem('navigation', currentPath);

      setNavigationHistory({
        navigationItems: [{ pathname: location.pathname, search: location.search, title: title }],
        dropdownItems: [],
      });
    } else {
      const storageIndex = navigationItems.findIndex((item: NavigationItem) => item.pathname === location.pathname);

      if (storageIndex !== -1) {
        navigationItems[storageIndex] = { pathname: location.pathname, search: location.search, title: title };
        navigationItems = navigationItems.slice(0, storageIndex + 1);
      } else {
        navigationItems.push({ pathname: location.pathname, search: location.search, title: title });
      }
      const { dropdownValues, navigationHistoryValues } = getNavigationItems(navigationItems);
      sessionStorage.setItem('navigation', JSON.stringify(navigationItems));
      setNavigationHistory({ navigationItems: navigationHistoryValues, dropdownItems: dropdownValues });
    }
  }, [location, getNavigationTitle]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!event.composedPath().includes(divRef.current as HTMLDivElement)) {
        setOpen(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.breadcrumbs} ref={divRef}>
      <div className={styles.crumb}>
        <Link to={'/dashboard/'}>
          <svg
            className={styles.home}
            onClick={() => sessionStorage.removeItem('navigation')}
            width="12"
            height="12.5"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.6769 4.98554L6.67688 0.268039C6.67443 0.265906 6.67213 0.263609 6.67 0.261164C6.48591 0.0937469 6.24602 0.000976563 5.99719 0.000976562C5.74836 0.000976563 5.50846 0.0937469 5.32438 0.261164L5.3175 0.268039L0.323125 4.98554C0.221249 5.07922 0.139927 5.19303 0.0843072 5.31976C0.028687 5.44649 -2.10268e-05 5.58339 1.15549e-08 5.72179V11.4999C1.15549e-08 11.7651 0.105357 12.0195 0.292893 12.207C0.48043 12.3946 0.734784 12.4999 1 12.4999H4C4.26522 12.4999 4.51957 12.3946 4.70711 12.207C4.89464 12.0195 5 11.7651 5 11.4999V8.49991H7V11.4999C7 11.7651 7.10536 12.0195 7.29289 12.207C7.48043 12.3946 7.73478 12.4999 8 12.4999H11C11.2652 12.4999 11.5196 12.3946 11.7071 12.207C11.8946 12.0195 12 11.7651 12 11.4999V5.72179C12 5.58339 11.9713 5.44649 11.9157 5.31976C11.8601 5.19303 11.7788 5.07922 11.6769 4.98554ZM11 11.4999H8V8.49991C8 8.2347 7.89464 7.98034 7.70711 7.79281C7.51957 7.60527 7.26522 7.49991 7 7.49991H5C4.73478 7.49991 4.48043 7.60527 4.29289 7.79281C4.10536 7.98034 4 8.2347 4 8.49991V11.4999H1V5.72179L1.00688 5.71554L6 0.999914L10.9937 5.71429L11.0006 5.72054L11 11.4999Z"
              fill="#7F8495"
            />
          </svg>
        </Link>
        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 0.5L5 4.5L1 8.5" stroke="#7F8495" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>

      {navigationHistory.navigationItems?.length
        ? navigationHistory.navigationItems.map((item: NavigationItem, index: number) => (
            <div key={index} className={styles.crumb}>
              {item.title === '...' ? (
                <button className={styles.button} onClick={() => setOpen(!open)}>
                  <div className={styles.dots}>{item.title}</div>
                  {!!open && (
                    <div className={styles.popup}>
                      {navigationHistory.dropdownItems?.map(item => (
                        <div key={item.title} className={styles.popupItem}>
                          <Link to={`${item.pathname}${item.search}`}>{item.title}</Link>
                        </div>
                      ))}
                    </div>
                  )}
                </button>
              ) : (
                <button className={styles.button}>
                  <Link to={`${item.pathname}${item.search}`}>{item.title}</Link>
                  {item.title?.length * letterWidth > 150 ? (
                    <div className={styles.hiddenMessage}>
                      <p>{item.title}</p>
                      <div className={styles.arrowDown}></div>
                    </div>
                  ) : (
                    ''
                  )}
                </button>
              )}
              {navigationHistory?.navigationItems && index !== navigationHistory.navigationItems.length - 1 ? (
                <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 0.5L5 4.5L1 8.5" stroke="#7F8495" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              ) : (
                ''
              )}
            </div>
          ))
        : ''}
    </div>
  );
};

export default NavigationHistory;
