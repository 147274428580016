import httpService, { HttpService } from './httpService';
import { RequestParams } from 'utils/mapParams';

export class BusinessBillingTypesService {
  constructor(private _httpService: HttpService) {}

  async getBusinessBillingTypes(params: Partial<RequestParams>) {
    const res = this._httpService.get('/business/billing-type', { queryStringParameters: params });
    return res;
  }
}

const businessBillingTypesService = new BusinessBillingTypesService(httpService);

export default businessBillingTypesService;
