import { createSlice } from '@reduxjs/toolkit';
import { getBusinessPipelinesList } from './businessPipelines.thunk';
import { BusinessPipelinesInitialState } from 'models/interfaces/businessPipelines.interface';

const initialState: BusinessPipelinesInitialState = {
  pipelines: null,
  loading: false,
  error: null,
};

const projectsSlice = createSlice({
  name: 'businessPipelines',
  initialState,
  reducers: {},
  extraReducers: {
    [getBusinessPipelinesList.pending.toString()]: state => {
      state.pipelines = null;
      state.loading = true;
      state.error = null;
    },
    [getBusinessPipelinesList.fulfilled.toString()]: (state, { payload }) => {
      state.pipelines = payload.items;
      state.loading = false;
    },
    [getBusinessPipelinesList.rejected.toString()]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export default projectsSlice.reducer;
