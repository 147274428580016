import { createAsyncThunk } from '@reduxjs/toolkit';
import servicesService from 'services/servicesService';
import { RequestParams } from 'utils/mapParams';
import { CreateServiceParams, UpdateServicesParams } from 'models/interfaces/services.interface';
import { enqueueSnackbar } from 'store/notifications';
import { NotificationType } from 'services/notificationService';

export const getServices = createAsyncThunk(
  'services/getServices',
  async (data: { params: Partial<RequestParams> }, thunkApi) => {
    try {
      const res = await servicesService.getServices(data.params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkApi.rejectWithValue(err.message.original);
    }
  }
);

export const createService = createAsyncThunk('services/createService', async (params: CreateServiceParams, thunkAPI) => {
  const { values, callback } = params;
  try {
    const res = await servicesService.createService(values);
    callback();
    thunkAPI.dispatch(
      enqueueSnackbar({
        options: {
          key: new Date().getTime() + Math.random(),
          variant: NotificationType.success,
          body: 'Service created successfully!',
          title: 'Services',
        },
      })
    );
    return res;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const updateService = createAsyncThunk('services/updateService', async (params: UpdateServicesParams, thunkAPI) => {
  const { id, values } = params;
  try {
    const res = await servicesService.updateService({ id, values });
    thunkAPI.dispatch(
      enqueueSnackbar({
        options: {
          key: new Date().getTime() + Math.random(),
          variant: NotificationType.success,
          body: 'Service Details updated successfully!',
          title: 'Services Details',
        },
      })
    );
    return res;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const deleteService = createAsyncThunk('services/deleteService', async (id: number, thunkAPI) => {
  try {
    await servicesService.deleteService(id);
    thunkAPI.dispatch(
      enqueueSnackbar({
        options: {
          key: new Date().getTime() + Math.random(),
          variant: NotificationType.success,
          body: 'Service deleted successfully!',
          title: 'Services',
        },
      })
    );
    return id;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});