import { createAsyncThunk } from '@reduxjs/toolkit';
import businessBillingTypesService from 'services/businessBillingTypesService';
import { RequestParams } from 'utils/mapParams';

export const getBusinessBillingTypesList = createAsyncThunk(
  'businessBillingTypes/getBusinessBillingTypesList',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const res = await businessBillingTypesService.getBusinessBillingTypes(params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
