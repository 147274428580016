import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getContracts, getContractById, updateContract } from './contracts.thunk';
import { ContractsState, Contract } from 'models/interfaces/contracts.interface';

const initialState: ContractsState = {
  items: [],
  contract: null,
  total: 0,
  pages: 0,
  loading: false,
  error: null,
};

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    clearContracts: () => ({
      ...initialState,
    }),
    updateContractStatus: (state, action: PayloadAction<{ id: number; status: string }>) => {
      state.items = state.items.map(contract =>
        contract.id === action.payload.id ? { ...contract, status: action.payload.status } : contract
      );
    },
    updateContractLocally: (state, action: PayloadAction<{ name: any; value: any }>) => {
      if (state.contract) {
        state.contract = { ...state.contract, [action.payload.name]: action.payload.value };
      }
    },
  },
  extraReducers: {
    [getContracts.pending.toString()]: state => {
      state.items = [];
      state.loading = true;
      state.error = null;
    },
    [getContracts.fulfilled.toString()]: (state, action) => {
      state.loading = false;

      if (action.meta.arg?.id) {
        state.items = {
          ...state.items,
          [action.meta.arg.id]: action.payload.items,
        };
      } else {
        state.items = action.payload.items;
        state.total = action.payload.total;
        state.pages = action.payload.pages;
      }
    },
    [getContracts.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getContractById.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [getContractById.fulfilled.toString()]: (state, { payload }) => {
      state.contract = payload;
      state.loading = false;
    },
    [getContractById.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [updateContract.fulfilled.toString()]: (state, action) => {
      state.contract = action.payload;
    },
  },
});

export const { clearContracts, updateContractStatus, updateContractLocally } = contractsSlice.actions;
export default contractsSlice.reducer;
