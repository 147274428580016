import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMonths, endOfMonth, endOfQuarter, format, startOfMonth, startOfQuarter } from 'date-fns';
import cx from 'classnames';

import { Card, CardBody } from 'reactstrap';
import { DropdownList, Multiselect } from 'react-widgets';
import ProjectCalendarTable from './Table/ProjectCalendarTable';
import Datepicker from 'components/Datepicker/Datepicker';
import ProjectFilter from 'common/ProjectFilter/ProjectFilter';
import Loader from 'common/Loader';
import Toggle from 'common/Toggle/Toggle';

import { getProjectCalendar } from 'store/projectCalendar/projectCalendar.thunk';
import { getTeamsListThunk } from 'store/teams/teams.thunk';
import { EVENT_EMPLOYEES_ACTION_TYPE, EmployeeInfoT, getEmployees } from 'store/eventsEmployees';
import { clearOpenedRowsCounter, setNbRRhidden } from 'store/projectCalendar';
import { clearDataWithId } from 'store/resourceRequests';
import { AppState } from 'store/configureStore';

import { useQuery } from 'hooks/queryHook';
import { useClickOutside } from 'hooks/useClickOutside';

import { DATE_PICKER } from 'constants/common';
import { Role } from 'constants/roles';
import {
  NOT_ACTIVE_STATUSES,
  PIR_ACTIVE_STATUSES,
  PIR_STATUSES,
  changeProjectsOrder,
  getFirstAndLastDay,
  getMonthsList,
} from './constants';
import { plus } from 'constants/icons';
import { getFullName } from 'helpers/companyStructureHeplers';
import { formatEmployees } from 'utils/formatDatas';
import { RequestParams } from 'utils/mapParams';
import TeamModel from 'models/team.model';
import { MonthChangeActionT, ProjectAndPIRs, ProjectPIR } from 'models/interfaces/projectCalendar.interface';
import CreateRRmodal from 'common/Modals/CreateRRmodal/CreateRRmodal';

import styles from '../tableStyles.module.scss';
import currentStyles from './projectCalendarStyles.module.scss';

const ProjectCalendar = () => {
  const dispatch = useDispatch();
  const pirSelectRef = useRef<HTMLDivElement>(null);

  const { projects, nbRRhidden, openedRowsCounter, loading } = useSelector(
    (state: AppState) => state.projectCalendarReducer
  );
  const { teams } = useSelector((state: AppState) => state.teamsReducer);
  const { deliveryTeamEmployees } = useSelector((state: AppState) => state.eventsEmployees);
  const { role } = useSelector((state: AppState) => state.account?.user);

  const {
    fromDate,
    toDate,
    projectKey,
    delivery,
    pirStatus,
    setFromToDate,
    setProjectKeyValue,
    setDelivery,
    setPirStatus,
  } = useQuery();

  const pirStatusValues = useMemo(
    () => PIR_STATUSES.filter(status => pirStatus.includes(status.toLowerCase().split(' ').join('-'))),
    [pirStatus]
  );
  const canCreateRR = useMemo(() => role === Role.vp || role === Role.pm || role === Role.delivery, [role]);

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(pirStatusValues || []);
  const [hiddenProjects, setHiddenProjects] = useState<number[]>([]);
  const [openCreateRRmodal, setOpenCreateRRmodal] = useState<boolean>(false);
  const [selectedPir, setSelectedPir] = useState<ProjectPIR | null>(null);

  const actionType = {
    request: EVENT_EMPLOYEES_ACTION_TYPE.DELIVERY_TEAM_EMPLOYEES_REQUEST,
    success: EVENT_EMPLOYEES_ACTION_TYPE.DELIVERY_TEAM_EMPLOYEES_SUCCESS,
    failure: EVENT_EMPLOYEES_ACTION_TYPE.DELIVERY_TEAM_EMPLOYEES_FAILURE,
  };

  const allActiveStatusesStr = PIR_ACTIVE_STATUSES.map((value: string) =>
    value.toLowerCase().split(' ').join('-')
  ).join(',');

  const getData = useCallback(() => {
    if (!fromDate || !toDate) {
      const [startDate, endDate] = getFirstAndLastDay();
      setFromToDate(startDate, endDate, 'all-active');
      setSelectedStatuses(['All Active']);
    } else {
      const params: Partial<RequestParams> = {
        fromDate: fromDate,
        toDate: toDate,
      };

      if (projectKey) {
        params.projectKey = projectKey;
      }

      if (delivery) {
        params.delivery = delivery;
      }

      if (pirStatus) {
        if (
          pirStatus.includes('start-failed') ||
          pirStatus.includes('rejected') ||
          pirStatus.includes('stopped') ||
          pirStatus.includes('finished') ||
          pirStatus.includes('closed')
        ) {
          if (pirStatus.includes('all-active')) {
            params.status = `${allActiveStatusesStr},${pirStatus}`;
          } else {
            params.status = pirStatus;
          }
        } else if (pirStatus.includes('all-active')) {
          params.status = NOT_ACTIVE_STATUSES.map((value: string) => value.toLowerCase().split(' ').join('-')).join(
            ','
          );
          params.statusExcluded = true;
        } else {
          params.status = pirStatus;
        }
      }

      dispatch(getProjectCalendar(params));
    }
  }, [fromDate, toDate, delivery, projectKey, pirStatus]);

  const getDeliveryManagersList = () => {
    const deliveryTeam = teams?.find((team: TeamModel) => team.title.toLowerCase() === 'delivery team');
    if (deliveryTeam) {
      dispatch(getEmployees({ team: deliveryTeam.id }, actionType));
    }
  };

  useEffect(() => {
    if (teams === null) {
      dispatch(getTeamsListThunk());
    }

    getData();
  }, [getData]);

  useEffect(() => {
    if (deliveryTeamEmployees === null && teams?.length) {
      getDeliveryManagersList();
    }
  }, [teams]);

  useEffect(() => {
    if (loading) {
      dispatch(clearOpenedRowsCounter());
      setHiddenProjects([]);
    }
  }, [loading]);

  const monthsList = useMemo(() => {
    if (fromDate) {
      return getMonthsList(fromDate, toDate);
    } else {
      return [];
    }
  }, [fromDate, toDate]);

  const filteredProjects: Required<ProjectAndPIRs>[] = useMemo(() => {
    return changeProjectsOrder(projects);
  }, [projects]);

  const projectIds: number[] = filteredProjects.map((item: Required<ProjectAndPIRs>) => item.project.id);

  const deliveryManagersList = useMemo(() => {
    if (deliveryTeamEmployees !== null) {
      return formatEmployees(deliveryTeamEmployees?.employees);
    }
  }, [deliveryTeamEmployees]);

  const collapseProjects = () => {
    setHiddenProjects(projectIds);
    dispatch(clearOpenedRowsCounter());
  };

  const onDateChange = (date: Date) => {
    dispatch(clearDataWithId());

    setFromToDate(
      format(startOfQuarter(date), DATE_PICKER.dateFormatToPayload),
      format(endOfQuarter(date), DATE_PICKER.dateFormatToPayload)
    );
  };

  const onDeLiveryManagerChange = (manager: EmployeeInfoT) => {
    setDelivery(manager.id === 0 ? '' : String(manager.id));
  };

  const onPirStatusChange = () => {
    const statuses = selectedStatuses.map((value: string) => value.toLowerCase().split(' ').join('-')).join(',');

    if (statuses !== pirStatus) {
      setPirStatus(selectedStatuses.length ? statuses : '');
    }
  };

  const onMonthChange = (action: MonthChangeActionT) => {
    const firstDay = addMonths(new Date(fromDate), action === 'next' ? 1 : -1);
    const lastDay = addMonths(new Date(toDate), action === 'next' ? 1 : -1);

    const newValueFromDate = format(startOfMonth(firstDay), 'yyyy-MM-dd');
    const newValueToDate = format(endOfMonth(lastDay), 'yyyy-MM-dd');

    setFromToDate(newValueFromDate, newValueToDate);
  };

  const openModal = (value: ProjectPIR) => {
    setSelectedPir(value);
    setOpenCreateRRmodal(true);
  };

  const closeModal = () => {
    setSelectedPir(null);
    setOpenCreateRRmodal(false);
  };

  useClickOutside(pirSelectRef, onPirStatusChange);

  const deliveryManager = deliveryTeamEmployees?.employees?.find(
    (employee: EmployeeInfoT) => employee.id === Number(delivery)
  );

  const startDate = fromDate ? new Date(fromDate) : startOfQuarter(new Date());
  const endDate = toDate ? new Date(toDate) : endOfQuarter(new Date());

  return (
    <>
      {canCreateRR && (
        <div className={styles.buttonWrap}>
          <button className={cx(styles.button, styles.topButton)} onClick={() => setOpenCreateRRmodal(true)}>
            {plus} Create RR
          </button>
        </div>
      )}

      <Card className="main-card mb-3">
        <CardBody>
          <div className={currentStyles.cardTopBlock}>
            <div className="bp-header">
              <div className="filters-block">
                <div className={cx(currentStyles.wrapDatepicker, 'dropdown-filter')}>
                  <div className="label-wrapper">Date</div>
                  <Datepicker
                    selected={startDate}
                    dateFormat={`${DATE_PICKER.dateFormatFullYear},`}
                    showMonthYearPicker={false}
                    showQuarterYearPicker
                    onChange={onDateChange}
                  />
                  <div className={currentStyles.monthRange}>
                    {`${format(startDate, 'MMM')}-${format(endDate, 'MMM')}`}
                  </div>
                </div>

                <ProjectFilter setFilterValue={setProjectKeyValue} />

                <div className="dropdown-filter">
                  <div className="label-wrapper">Delivery Manager</div>
                  <DropdownList
                    value={deliveryManager ? deliveryManager : ''}
                    data={deliveryManagersList || []}
                    textField={(item: any) => getFullName(item)}
                    placeholder="All"
                    filter="contains"
                    onChange={manager => onDeLiveryManagerChange(manager)}
                    busy={deliveryTeamEmployees === null}
                  />
                </div>

                <div className="dropdown-filter" ref={pirSelectRef}>
                  <div className="label-wrapper">PIR Status</div>
                  <Multiselect
                    value={selectedStatuses}
                    defaultValue={selectedStatuses}
                    data={PIR_STATUSES}
                    placeholder="All"
                    filter="contains"
                    onChange={value => setSelectedStatuses(value)}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div className="d-flex align-items-center">
                <div>Collapse all</div>
                <Toggle
                  checked={projectIds.length ? hiddenProjects.length === projectIds.length : false}
                  onChange={() =>
                    hiddenProjects.length < projects.length ? collapseProjects() : setHiddenProjects([])
                  }
                  toggleMargin="ml-3"
                  disabled={!filteredProjects.length}
                />
              </div>

              <div className="d-flex align-items-center ml-4">
                <div>Hide non-billable RRs</div>
                <Toggle
                  checked={nbRRhidden}
                  onChange={() => dispatch(setNbRRhidden(!nbRRhidden))}
                  toggleMargin="ml-3"
                  disabled={!openedRowsCounter || hiddenProjects.length === projects.length}
                />
              </div>
            </div>
          </div>

          {projects?.length ? (
            <ProjectCalendarTable
              projects={filteredProjects}
              monthsList={monthsList}
              hiddenProjects={hiddenProjects}
              setHiddenProjects={setHiddenProjects}
              onMonthChange={onMonthChange}
              openModal={openModal}
              canCreateRR={canCreateRR}
              loading={loading}
            />
          ) : loading ? (
            <div className="mt-2">
              <Loader />
            </div>
          ) : (
            'Project calendar is empty'
          )}
        </CardBody>
      </Card>

      {openCreateRRmodal && (
        <CreateRRmodal
          onClose={closeModal}
          currentProject={
            selectedPir
              ? projects?.find(
                  (project: ProjectAndPIRs) => project.project?.projectKey === selectedPir.linkedProjectKey
                )?.project
              : null
          }
          currentPir={selectedPir}
          callback={getData}
          setOpenModal={setOpenCreateRRmodal}
        />
      )}
    </>
  );
};

export default ProjectCalendar;
