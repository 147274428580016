import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import cx from 'classnames';

import { Table } from 'reactstrap';
import FormikDropdown from 'common/Formik/FormikDropdown';

import { updateEmployeeById } from 'store/allEmployees/allEmployees.thunk';
import EmployeeWithMentorModel from 'models/employeeWithMentor.model';
import { getFullName } from 'helpers/companyStructureHeplers';
import { editIcon } from 'constants/icons';

import styles from '../tableStyles.module.scss';

const MentorsAssignmentTable: FC<{ data: EmployeeWithMentorModel[]; dropdownData: EmployeeWithMentorModel[] }> = ({
  data,
  dropdownData,
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState<number | boolean>(false);

  return (
    <div className={styles.tableWrapper} style={{ overflow: 'visible' }}>
      <Table className={cx(styles.tableContainer, 'align-middle mb-0 custom-table text-left')}>
        <thead>
          <tr>
            <th>Member</th>
            <th>Mentor</th>
          </tr>
        </thead>

        <tbody>
          {data.map((employee: EmployeeWithMentorModel, index: number) => (
            <tr key={employee.id} className={cx(styles.removeBorder, styles.rowHover)}>
              <td style={{ width: '50%' }}>{`${employee.firstName} ${employee.lastName}`}</td>
              <td>
                {editMode === employee.id ? (
                  <Formik
                    initialValues={{ mentor: employee.mentor.id || null }}
                    onSubmit={(values: { mentor: number | null }) => {
                      dispatch(
                        updateEmployeeById({
                          id: String(employee.id),
                          edit: 'mentor',
                          employeeData: { mentorId: values.mentor },
                        })
                      );
                      setEditMode(false);
                    }}
                  >
                    {({ values, handleSubmit }) => (
                      <Form className="filters-block" style={{ maxWidth: '220px' }}>
                        <FormikDropdown
                          name="mentor"
                          placeholder="Unassigned"
                          data={dropdownData}
                          textField={(item: any) => getFullName(item)}
                          defaultValue={employee.mentor?.id !== null ? employee.mentor : dropdownData[0]}
                          className={cx('dropdown-filter', 'assigneesDropdown', styles.dropdownWrapper)}
                          handleSubmit={handleSubmit}
                          setEditMode={setEditMode}
                          dataKey="id"
                          clearValue={true}
                          addFilter={true}
                        />
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div role="button" className={styles.itemsWrapper} onClick={() => setEditMode(employee.id)}>
                    {(employee.mentor.firstName || employee.mentor.lastName) && (
                      <div className="mr-2">{`${employee.mentor.firstName} ${employee.mentor.lastName}`}</div>
                    )}
                    {editIcon}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MentorsAssignmentTable;
