import { RequestParams } from 'utils/mapParams';
import httpService, { HttpService } from './httpService';
import { createService, UpdateService } from 'models/interfaces/services.interface';

export class ServicesService {
  constructor(private _httpService: HttpService) {}

  async getServices(params: Partial<RequestParams>) {
    const res = this._httpService.get('/business/services', { queryStringParameters: params });
    return res;
  }

  async createService(params: createService) {
    const res = this._httpService.post('/business/services', { body: params });
    return res;
  }

  async updateService(params: { id: number; values: UpdateService }) {
    const res = await this._httpService.put(`/business/services/${params.id}`, {
      body: params.values,
    });
    return res;
  }

  async deleteService( id: number ) {
    const res = await this._httpService.delete(`/business/services/${id}`, {});
    return res;
  }
}

const servicesService = new ServicesService(httpService);

export default servicesService;
