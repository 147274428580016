import httpService, { HttpService } from './httpService';
import { RequestParams } from 'utils/mapParams';

export class BusinessPaymentService {
  constructor(private _httpService: HttpService) {}

  async getBusinessPaymentList(params: Partial<RequestParams>) {
    const res = this._httpService.get('/business/payment-basis', { queryStringParameters: params });
    return res;
  }
}

const businessPaymentService = new BusinessPaymentService(httpService);

export default businessPaymentService;
