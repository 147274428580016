import httpService, { HttpService } from './httpService';
import { RequestParams } from 'utils/mapParams';

export class BusinessPipelinesService {
  constructor(private _httpService: HttpService) {}

  async getBusinessPipelinesList(params: Partial<RequestParams>) {
    const res = this._httpService.get('/business/pipelines', { queryStringParameters: params });
    return res;
  }
}

const businessPipelinesService = new BusinessPipelinesService(httpService);

export default businessPipelinesService;
