import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import cx from 'classnames';

import FormikDropdown from 'common/Formik/FormikDropdown';
import FormikInput from 'common/Formik/FormikInput';
import FormikDateRangePicker from 'common/Formik/FormikDateRangePicker';

import { getEmployees } from 'store/allEmployees/allEmployees.thunk';
import { getSelectedEmployeeVsdData } from 'store/vsd/vsd.thunk';
import { AppState } from 'store/configureStore';

import TeamModel from 'models/team.model';
import { EmployeeDto } from 'models/dto/employee.dto';
import { VsdIssue } from 'models/interfaces/vsd.interface';
import { WorkforceInputsProps } from 'models/interfaces/createRR.interface';

import { DATE_PICKER, BILLING_SOURCES, SERVICE_TYPES } from 'constants/common';
import { GRADE_DROPDOWN } from 'components/ProfileDashboard/constants';
import { getFullName } from 'helpers/companyStructureHeplers';

import styles from '../../../components/modal.module.scss';

const WorkforceFormInputs = ({ values, setFieldValue }: WorkforceInputsProps) => {
  const dispatch = useDispatch();

  const { teams } = useSelector((state: AppState) => state.teamsReducer);
  const { dataWithId } = useSelector((state: AppState) => state.allEmployeesReducer);
  const { dataWithId: vsdData } = useSelector((state: AppState) => state.vsdReducer);

  const [startDate, endDate] = values.workforce.dateRange;

  const getVsdForSelectedWorkforce = (fromDate: Date | null, toDate: Date | null, employeeID?: string) => {
    if (employeeID && fromDate && toDate) {
      dispatch(
        getSelectedEmployeeVsdData({
          fromDate: format(fromDate as Date, DATE_PICKER.dateFormatToPayload),
          toDate: format(toDate as Date, DATE_PICKER.dateFormatToPayload),
          employees: [employeeID],
        })
      );
    }
  };

  return (
    <div className={styles.inputWrap}>
      <div className="filters-block">
        <div className="dropdown-filter" style={{ maxWidth: '375px' }}>
          <p className="label-wrapper">
            Team<span className={styles.required}>*</span>
          </p>
          <FormikDropdown
            name={`workforce.teamId`}
            placeholder="Select Team"
            data={teams || []}
            handleChange={(value: TeamModel) => {
              //@ts-ignore
              setFieldValue(`workforce.workforce`, '');
              dispatch(getEmployees({ id: value.id, params: { team: value.id } }));
            }}
            textField="title"
            dataKey="id"
            addFilter={true}
          />
        </div>
      </div>

      <div>
        <p className="label-wrapper">
          FTE<span className={styles.required}>*</span>
        </p>
        <FormikInput
          name={`workforce.fte`}
          type="number"
          placeholder="Enter FTE"
          className={styles.input}
          inputNumerProps={{ step: 0.25, max: 20, min: 0.25 }}
          disabled={!!values.workforce.workforce}
        />
      </div>

      <div className="filters-block">
        <div className="dropdown-filter" style={{ maxWidth: '375px' }}>
          <p className="label-wrapper">
            Service Type<span className={styles.required}>*</span>
          </p>
          <FormikDropdown
            name={`workforce.serviceType`}
            placeholder="Select Service Type"
            data={SERVICE_TYPES || []}
            textField="title"
            addFilter={true}
          />
        </div>
      </div>

      {!!values.workforce.teamId && (
        <>
          <div className="filters-block ">
            <div className="dropdown-filter" style={{ maxWidth: '375px' }}>
              <p className="label-wrapper">Workforce</p>
              <FormikDropdown
                name={`workforce.workforce`}
                placeholder="Select Workforce"
                closeIconClassName={styles.closeIconDropdown}
                data={dataWithId[values.workforce.teamId] || []}
                handleChange={(value: EmployeeDto) =>
                  getVsdForSelectedWorkforce(startDate, endDate, value?.atlassianId)
                }
                textField={(item: any) => getFullName(item)}
                dataKey="atlassianId"
                addFilter={true}
                clearValue={true}
                disabled={values.workforce.fte > 1}
              />
            </div>
          </div>

          {values.workforce?.workforce && !!vsdData?.[values.workforce.workforce]?.issues?.length && (
            <div className="mb-2">
              <span className="mr-2" style={{ color: '#ff4f5b' }}>
                VSD:
              </span>
              {vsdData?.[values.workforce.workforce].issues.map((vsd: VsdIssue) => (
                <a key={vsd.key} href={vsd.link} className="mr-2" target="_blank">
                  {vsd.key}
                </a>
              ))}
            </div>
          )}
        </>
      )}

      <div>
        <p className="label-wrapper">
          Date time<span className={styles.required}>*</span>
        </p>
        <FormikDateRangePicker
          name={`workforce.dateRange`}
          dateFormat={DATE_PICKER.dateFormatToStandard}
          placeholderText="Start date - End date"
          className={cx(styles.input, 'mr-2')}
          onChange={(value: Array<Date | null>) => {
            const [start, end] = value;

            if (end && start) {
              getVsdForSelectedWorkforce(start, end, values.workforce.workforce);
            }
          }}
          minDate={values.pir?.periodStartDate ? new Date(values.pir.periodStartDate) : ''}
          maxDate={values.pir?.periodEndDate ? new Date(values.pir.periodEndDate) : ''}
          showYearDropdown
        />
      </div>

      <div className="filters-block">
        <div className="dropdown-filter" style={{ maxWidth: '375px' }}>
          <p className="label-wrapper">Billing Source</p>

          <FormikDropdown
            name={`workforce.billingSource`}
            placeholder="Select Billing Source"
            data={BILLING_SOURCES}
            closeIconClassName={styles.closeIconDropdown}
            clearValue
          />
        </div>
      </div>

      <div className="filters-block">
        <div className="dropdown-filter" style={{ maxWidth: '375px' }}>
          <p className="label-wrapper">Grade</p>

          <FormikDropdown
            name={`workforce.grade`}
            placeholder="Select Grade"
            data={GRADE_DROPDOWN}
            closeIconClassName={styles.closeIconDropdown}
            clearValue
          />
        </div>
      </div>

      <FormikInput
        name={`workforce.description`}
        type="textarea"
        placeholder="Add Description"
        className={styles.textarea}
        label="Description"
      />
    </div>
  );
};

export default WorkforceFormInputs;
