import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';

import FormikDropdown from 'common/Formik/FormikDropdown';
import FormikInput from 'common/Formik/FormikInput';
import FormikDatePicker from 'common/Formik/FormikDatePicker';
import Drawer from './Drawer';
import Loader from 'common/Loader';
import ActionButtons from './ActionButtons';

import { createBusinessProject } from 'store/businessProjects/businessProjects.thunk';
import { getProjectsList } from 'store/projects/projects.thunk';
import { getBusinessPipelinesList } from 'store/businessPipelines/businessPipelines.thunk';
import { getEngagementModelList } from 'store/engagementModel/engagementModel.thunk';
import { getBusinessPaymentList } from 'store/businessPayments/businessPayments.thunk';
import { getProjectInitRequests } from 'store/projectInitRequests/projectInitRequests.thunk';
import { createContract } from 'store/contracts/contracts.thunk';
import { AppState } from 'store/configureStore';

import { DATE_PICKER } from 'constants/common';
import {
  CreateContractModalProps,
  CreateContractInitialValues,
  Project,
} from 'models/interfaces/createContract.interface';
import { PIRModel } from 'models/interfaces/contracts.interface';

import styles from '../../../components/modal.module.scss';
import drawerStyles from './styles.module.scss';

const CREATE_CONTRACT_SCHEMA = Yup.object({
  name: Yup.string().min(2, 'Less than 2 characters').max(128, 'Contract Name is too long').required('Field required'),
  startDate: Yup.string().required('Field required'),
  endDate: Yup.string().when('repeat', {
    is: (repeat: boolean) => !repeat,
    then: Yup.string().required('Field required').nullable(),
    otherwise: Yup.string().nullable(),
  }),
  legalEntity: Yup.string().min(2, 'Less than 2 characters').max(128, 'Legal Entity is too long').nullable().optional(),
  signNowDocumentId: Yup.string().nullable().optional(),
  projectId: Yup.object().nullable().optional(),
  projectInitRequestKey: Yup.object().nullable().optional(),
  pipelineId: Yup.object().nullable().optional(),
  engagementModelId: Yup.object().nullable().optional(),
  invoiceDueDate: Yup.string().nullable().optional(),
  paymentBasisId: Yup.object().nullable().optional(),
});



const CreateContractModal = ({
  onClose,
  callback,
  setOpenModal,
}: CreateContractModalProps) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { projects } = useSelector((state: AppState) => state.projectsReducer);
  const { pipelines } = useSelector((state: AppState) => state.businessPipelinesReducer);
  const { models } = useSelector((state: AppState) => state.engagementModelsReducer);
  const { payments } = useSelector((state: AppState) => state.businessPaymentsReducer);
  const { projectInitRequests } = useSelector((state: AppState) => state.projectInitRequestsReducer);

  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  useEffect(() => {
    if (selectedProject) {
      dispatch(getProjectInitRequests({ page: 1, size: 250, projectKey: selectedProject.projectKey }));
    } else {
      dispatch(getProjectInitRequests({ page: 1, size: 250 }));
    }
  }, [selectedProject, dispatch]);

  useEffect(() => {
    dispatch(getBusinessPaymentList({ page: 1, size: 50 }));
    dispatch(getProjectsList({ page: 1, size: 250 }));
    dispatch(getProjectInitRequests({ page: 1, size: 250 }));
  }, []);

  const onProjectSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      const target = event.target as HTMLInputElement;
      dispatch(getProjectsList({ page: 1, size: 250, search: target.value }));
    }, 250),
    []
  );

  const onPipelinesSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      dispatch(getBusinessPipelinesList({ page: 1, size: 50 }));
    }, 250),
    []
  );

  const onModelsSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      dispatch(getEngagementModelList({ page: 1, size: 50 }));
    }, 250),
    []
  );

  const onPaymentsSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      dispatch(getBusinessPaymentList({ page: 1, size: 50 }));
    }, 250),
    []
  );

  const onPIRSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      const target = event.target as HTMLInputElement;
      dispatch(getProjectInitRequests({ page: 1, size: 250, search: target.value }));
    }, 250),
    []
  );

  const getFullPIRName = (item: Partial<PIRModel>) => (item ? `(${item.issueKey}) ${item.summary}` : '');

  const initialValues: CreateContractInitialValues = {
    name: '',
    startDate: '',
    endDate: '',
    legalEntity: null,
    signNowDocumentId: null,
    projectId: null,
    projectInitRequestKey: null,
    pipelineId: null,
    engagementModelId: null,
    invoiceDueDate: null,
    paymentBasisId: null,
  };

  const onSubmit = async (values: CreateContractInitialValues) => {
    setIsSubmitting(true);

    const projectValues = {
      name: values.projectId?.name || '',
      projectKey: values.projectId?.projectKey || '',
    };

    try {
      const newProjectAction = await dispatch(createBusinessProject({ values: projectValues, callback: () => {} }));
      const newProject = await (newProjectAction as any).payload;

      const formattedValues = await {
        pipelineId: values.pipelineId?.id || null,
        engagementModelId: values.engagementModelId?.id || null,
        paymentBasisId: values.paymentBasisId?.id || null,
        invoiceDueDate: values.invoiceDueDate ? format(new Date(values.invoiceDueDate), 'yyyy-MM-dd') : null,
        signNowDocumentId: values.signNowDocumentId || null,
        legalEntity: values.legalEntity || null,
        projectId: newProject?.id || null,
        projectInitRequestKey: values.projectInitRequestKey?.issueKey || null,
        name: values.name,
        startDate: format(new Date(values.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(values.endDate), 'yyyy-MM-dd'),
      };

      await dispatch(createContract({ values: formattedValues, callback }));
      await setOpenModal(false);
      await setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  return createPortal(
    <Formik
      initialValues={initialValues}
      validationSchema={CREATE_CONTRACT_SCHEMA}
      validateOnBlur={true}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, validateForm, setFieldTouched, isValid, dirty }) => (
        <Form>
          <Drawer
            className={cx(drawerStyles.drawer, drawerStyles.active, 'text-left')}
            title="Create Contract"
            onClose={onClose}
          >
            <div className={styles.inputWrap}>
              <div>
                <div className={cx(drawerStyles.gap, 'd-flex')}>
                  <p className="label-wrapper">Contract Name</p>
                  <p className={styles.required}>*</p>
                </div>
                <FormikInput
                  name="name"
                  onBlur={() => setFieldTouched('name', true)}
                  placeholder="Contract Name"
                  className={styles.input}
                />
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Start Date</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikDatePicker
                    name="startDate"
                    dateFormat={DATE_PICKER.dateFormatMonthAndDay}
                    placeholderText="yyyy/mm/dd"
                    className={styles.input}
                    maxDate={values?.endDate ? values?.endDate : ''}
                    showYearDropdown
                  />
                </div>

                <div>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">End Date</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikDatePicker
                    name="endDate"
                    dateFormat={DATE_PICKER.dateFormatMonthAndDay}
                    placeholderText="yyyy/mm/dd"
                    className={styles.input}
                    minDate={values?.startDate}
                    showYearDropdown
                  />
                </div>
              </div>

              <div>
                <div className="d-flex">
                  <p className="label-wrapper">Legal Entity</p>
                </div>
                <FormikInput
                  name="legalEntity"
                  onBlur={() => setFieldTouched('legalEntity', true)}
                  placeholder="Legal Entity"
                  className={styles.input}
                />
              </div>

              <div>
                <div className="d-flex">
                  <p className="label-wrapper">SignNow Document ID</p>
                </div>
                <FormikInput name="signNowDocumentId" placeholder="SignNow Document ID" className={styles.input} />
              </div>

              <div className="filters-block">
                <div className="dropdown-filter" style={{ maxWidth: '375px' }}>
                  <p className="label-wrapper">Project</p>
                  <FormikDropdown
                    name="projectId"
                    className={styles.clearIcon}
                    placeholder="Project"
                    data={projects || []}
                    defaultValue={undefined}
                    onDropdownSearch={onProjectSearch}
                    handleChange={(value: Project) => {
                      setSelectedProject(value);
                      setFieldValue('projectInitRequestKey', null);
                    }}
                    textField="name"
                    addFilter={true}
                    clearValue
                    clear={() => {
                      setSelectedProject(null);
                    }}
                  />
                </div>
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div className="dropdown-filter" style={{ maxWidth: '181px' }}>
                  <p className="label-wrapper">Linked PIR</p>
                  <FormikDropdown
                    name="projectInitRequestKey"
                    className={styles.clearIcon}
                    placeholder="PIR"
                    data={projectInitRequests || []}
                    defaultValue=""
                    onDropdownSearch={onPIRSearch}
                    textField={(item: any) => getFullPIRName(item)}
                    addFilter={true}
                    clearValue
                  />
                </div>
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div className="dropdown-filter" style={{ maxWidth: '181px' }}>
                  <p className="label-wrapper">Pipeline</p>
                  <FormikDropdown
                    name="pipelineId"
                    className={styles.clearIcon}
                    placeholder="Pipeline"
                    data={pipelines || []}
                    defaultValue=""
                    onDropdownSearch={onPipelinesSearch}
                    textField="name"
                    addFilter={true}
                    clearValue
                  />
                </div>

                <div className="dropdown-filter" style={{ maxWidth: '181px' }}>
                  <p className="label-wrapper">Engagement Model</p>
                  <FormikDropdown
                    name="engagementModelId"
                    className={styles.clearIcon}
                    placeholder="Engagement Model"
                    data={models || []}
                    defaultValue=""
                    onDropdownSearch={onModelsSearch}
                    textField="name"
                    addFilter={true}
                    clearValue
                  />
                </div>
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div style={{ minWidth: '181px' }}>
                  <div className="d-flex">
                    <p className="label-wrapper">Invoice Due Date</p>
                  </div>
                  <FormikDatePicker
                    name="invoiceDueDate"
                    dateFormat={DATE_PICKER.dateFormatMonthAndDay}
                    placeholderText="yyyy/mm/dd"
                    className={styles.input}
                    showYearDropdown
                  />
                </div>

                <div className="dropdown-filter" style={{ maxWidth: '181px' }}>
                  <p className="label-wrapper">Payment Basis</p>
                  <FormikDropdown
                    name="paymentBasisId"
                    className={styles.clearIcon}
                    placeholder="Payment Basis"
                    data={payments || []}
                    defaultValue=""
                    onDropdownSearch={onPaymentsSearch}
                    textField="name"
                    addFilter={true}
                    clearValue
                  />
                </div>
              </div>
            </div>
            {isSubmitting ? (
              <div className={drawerStyles.buttonWrapper} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <Loader />
              </div>
            ) : (
              <ActionButtons
                className={drawerStyles.buttonWrapper}
                cancelText="Cancel"
                submitText="Create"
                onCancel={onClose}
                isSubmitBtn
                disabled={!(isValid && dirty)}
              />
            )}
          </Drawer>
        </Form>
      )}
    </Formik>,
    document.body
  );
};

export default CreateContractModal;