import { createSlice } from '@reduxjs/toolkit';
import { getBusinessBillingTypesList } from './businessBillingTypes.thunk';
import { BusinessBillingTypesInitialState } from 'models/interfaces/businessBillingTypes.interface';

const initialState: BusinessBillingTypesInitialState = {
  billingTypes: null,
  loading: false,
  error: null,
};

const businessBillingTypesSlice = createSlice({
  name: 'businessBillingTypes',
  initialState,
  reducers: {},
  extraReducers: {
    [getBusinessBillingTypesList.pending.toString()]: state => {
      state.billingTypes = null;
      state.loading = true;
      state.error = null;
    },
    [getBusinessBillingTypesList.fulfilled.toString()]: (state, { payload }) => {
      state.billingTypes = payload.items;
      state.loading = false;
    },
    [getBusinessBillingTypesList.rejected.toString()]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export default businessBillingTypesSlice.reducer;