import httpService, { HttpService } from './httpService';
import { RequestParams } from 'utils/mapParams';

export class EngagementModelService {
  constructor(private _httpService: HttpService) {}

  async getEngagementModelList(params: Partial<RequestParams>) {
    const res = this._httpService.get('/business/engagement-model', { queryStringParameters: params });
    return res;
  }
}

const engagementModelService = new EngagementModelService(httpService);

export default engagementModelService;
