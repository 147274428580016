import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { format } from 'date-fns';
import { Card, CardBody } from 'reactstrap';
import debounce from 'lodash.debounce';
import Loader from 'common/Loader';
import AddServiceModal from 'common/Modals/CreateContractModal/AddServiceModal';
import ServicesTable from './ServicesTable';

import { getContractById } from 'store/contracts/contracts.thunk';
import { getServices } from 'store/services/services.thunk';
import { getProjectsList } from 'store/projects/projects.thunk';
import { getBusinessProjectsList } from 'store/businessProjects/businessProjects.thunk';
import { createBusinessProject } from 'store/businessProjects/businessProjects.thunk';
import { getProjectInitRequests } from 'store/projectInitRequests/projectInitRequests.thunk';
import { getBusinessPipelinesList } from 'store/businessPipelines/businessPipelines.thunk';
import { getEngagementModelList } from 'store/engagementModel/engagementModel.thunk';
import { getBusinessPaymentList } from 'store/businessPayments/businessPayments.thunk';
import { getBusinessBillingTypesList } from 'store/businessBillingTypes/businessBillingTypes.thunk';
import { clearContracts } from 'store/contracts';
import { updateContract } from 'store/contracts/contracts.thunk';
import { AppState } from '../../../store/configureStore';

import FormikInput from 'common/Formik/FormikInput';
import FormikDatePicker from 'common/Formik/FormikDatePicker';
import FormikDropdown from 'common/Formik/FormikDropdown';
import { Formik, Form } from 'formik';

import { Role } from 'constants/roles';
import { DATE_PICKER, CONTRACTS_STATUSES_EDIT } from 'constants/common';
import { plus } from '../../../constants/icons';
import { checkIcon } from '../../../constants/icons';

import { Contract, PIRModel } from 'models/interfaces/contracts.interface';
import { Project } from 'models/interfaces/createContract.interface';

import styles from '../../tableStyles.module.scss';
import wrapStyles from '../wrapper.module.scss';

const ContractDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { contract, loading } = useSelector((state: AppState) => state.contractsReducer);
  const { role } = useSelector((state: AppState) => state.account?.user);
  const [editContract, setEditContract] = useState<Contract>(contract);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [editField, setEditField] = useState<string>('');
  const [openCreateContractModal, setOpenCreateContractModal] = useState<boolean>(false);
  const { items: services, total, pages, totals, loading: servicesLoading, error } = useSelector((state: AppState) => state.servicesReducer);
  
  const { projects } = useSelector((state: AppState) => state.projectsReducer);
  const { businessProjects } = useSelector((state: AppState) => state.businessProjectsReducer);
  const { projectInitRequests } = useSelector((state: AppState) => state.projectInitRequestsReducer);
  const { pipelines } = useSelector((state: AppState) => state.businessPipelinesReducer);
  const { models } = useSelector((state: AppState) => state.engagementModelsReducer);
  const { payments } = useSelector((state: AppState) => state.businessPaymentsReducer);
  const { billingTypes } = useSelector((state: AppState) => state.businessBillingTypesReducer);
  

  const useDebouncedSearch = (dispatchAction: (search: string) => void) => {
    return useCallback(
      debounce((event: KeyboardEvent) => {
        const target = event.target as HTMLInputElement;
        dispatchAction(target.value);
      }, 250),
      [dispatchAction]
    );
  };

  const onProjectSearch = useDebouncedSearch(search => dispatch(getProjectsList({ page: 1, size: 250, search })));
  const onPIRSearch = useDebouncedSearch(search => dispatch(getProjectInitRequests({ page: 1, size: 250, search })));
  const onPipelineSearch = useDebouncedSearch(() => getBusinessPipelinesList({ page: 1, size: 50 }));
  const onModelsSearch = useDebouncedSearch(() => getEngagementModelList({ page: 1, size: 50 }));
  const onPaymentsSearch = useDebouncedSearch(() => getBusinessPaymentList({ page: 1, size: 50 }));

  const getContractServices = useCallback(() => {
    dispatch(getServices({ params: { contractId: id } }));
  }, [dispatch, id]);
  
  useEffect(() => {
    getContractServices();
  }, [dispatch, getContractServices]);

  useEffect(() => {
    dispatch(getProjectsList({ page: 1, size: 250 }));
    dispatch(getBusinessPipelinesList({ page: 1, size: 50 }));
    dispatch(getEngagementModelList({ page: 1, size: 50 }));
    dispatch(getBusinessPaymentList({ page: 1, size: 50 }));
    dispatch(getBusinessBillingTypesList({ page: 1, size: 50 }));
  }, []);

  useEffect(() => {
    if (contract) {
      setEditContract(contract);
      setSelectedProject(contract.project)
    }
  }, [contract]);

  useEffect(() => {
    if (selectedProject) {
      dispatch(getProjectInitRequests({ page: 1, size: 250, projectKey: selectedProject.projectKey }));
    } else {
      dispatch(getProjectInitRequests({ page: 1, size: 250 }));
    }
  }, [selectedProject]);

  useEffect(() => {
    !!id && dispatch(getContractById({ id: +id }));
    return () => {
      dispatch(clearContracts());
    };
    
  }, [dispatch, id]);

  const getFullPIRName = (item: Partial<PIRModel>) => {
    return item ? `${item.issueKey} ${item.summary ?? ''}${item.summary ? ' ' : ''}` : '';
  }

  const canAddService = useMemo(
    () => role === Role.vp || role === Role.sales || role === Role.delivery || role === Role.finance,
    [role]
  );
  

  const canEditContract = (role: Role, contractStatus: string): boolean => {
    const editableStatusesForLegalFinance = ['draft', 'legal paperwork', 'waiting for sign', 'signed'];
    switch (role) {
      case Role.vp:
        return true;
      case Role.delivery:
      case Role.sales:
        return contractStatus === 'draft';
      case Role.finance:
        return editableStatusesForLegalFinance.includes(contractStatus);
      default:
        return false;
    }
  };

  const isEditable = useMemo(() => {
    return canEditContract(role, editContract?.status);
  }, [role, editContract?.status]);

  if (loading) {
    return <div className="mb-4">{<Loader />}</div>;
  }

  const formatDate = (date: string | null | undefined): string | undefined =>
    date ? format(new Date(date), 'yyyy-MM-dd') : undefined;

  const handleSubmit = (field: keyof Contract, value: any) => {
    const normalizedValue = value === '' ? undefined : value;

    if (editContract[field] === normalizedValue) {
      setEditField('');
      return;
    }

    setEditContract(prev => ({ ...prev, [field]: normalizedValue }));

    const formattedValues = {
      pipelineId: editContract.pipeline?.id,
      engagementModelId: editContract.engagementModel?.id,
      paymentBasisId: editContract.paymentBasis?.id,
      invoiceDueDate: editContract.invoiceDueDate
        ? format(new Date(editContract.invoiceDueDate), 'yyyy-MM-dd')
        : undefined,
      signNowDocumentId: editContract.signNowDocumentId || undefined,
      legalEntity: editContract.legalEntity,
      projectId: editContract.project?.id,
      projectInitRequestKey: editContract.projectInitRequest?.issueKey || undefined,
      name: editContract.name,
      startDate: editContract.startDate ? format(new Date(editContract.startDate), 'yyyy-MM-dd') : undefined,
      endDate: editContract.endDate ? format(new Date(editContract.endDate), 'yyyy-MM-dd') : undefined,
      status: editContract.status,
    };

    const updateValues: Partial<typeof formattedValues> = { ...formattedValues };

    switch (field) {
      case 'project':
        updateValues.projectId = normalizedValue?.id;
        updateValues.projectInitRequestKey = undefined;
        break;
      case 'projectInitRequest':
        updateValues.projectInitRequestKey = normalizedValue?.issueKey;
        break;
      case 'pipeline':
        updateValues.pipelineId = normalizedValue?.id;
        break;
      case 'engagementModel':
        updateValues.engagementModelId = normalizedValue?.id;
        break;
      case 'paymentBasis':
        updateValues.paymentBasisId = normalizedValue?.id;
        break;
      case 'invoiceDueDate':
        updateValues.invoiceDueDate = normalizedValue;
        break;
      default:
        if (field in updateValues) {
          updateValues[field as keyof typeof updateValues] = normalizedValue;
        }
    }

    dispatch(updateContract({ id: Number(id), values: updateValues }));
    setEditField('');
  };

  const formattedStatus = (value: string) => {
    return value.toLowerCase();
  }

  return (
    <>
      {isEditable && (
        <div className={styles.buttonWrap}>
          <button className={cx(styles.button, styles.topButton)} onClick={() => setOpenCreateContractModal(true)}>
            {plus} Add Service
          </button>
        </div>
      )}
      <Card className="main-card mb-3">
        <CardBody>
          <div className="bp-header">
            {isEditable && editField === 'edit_name' ? (
              <Formik
                initialValues={{ name: editContract?.name || '' }}
                onSubmit={values => handleSubmit('name', values.name)}
              >
                {({ setFieldValue, values }) => (
                  <Form className={styles.formHeader}>
                    <FormikInput
                      name="name"
                      placeholder=""
                      className={styles.inputHeader}
                      onSubmit={e => {
                        setFieldValue('name', editContract.name);
                      }}
                      onHandleKeyDown={e => {
                        if (e.code === 'Enter') {
                          handleSubmit('name', e.target.value);
                        }
                        if (e.code === 'Escape') {
                          handleSubmit('name', editContract.name);
                        }
                      }}
                      onBlur={e => {
                        handleSubmit('name', editContract.name);
                      }}
                      autoFocus
                    />
                    <button
                      type="submit"
                      onMouseDown={e => e.preventDefault()}
                      onClick={e => {
                        handleSubmit('name', values.name);
                      }}
                    >
                      {checkIcon}
                    </button>
                  </Form>
                )}
              </Formik>
            ) : (
              <div
                onClick={() => {
                  setEditField('edit_name');
                }}
                className={`${wrapStyles.headerName} ${wrapStyles.headerHeight}`}
              >
                {editContract?.name}
              </div>
            )}
          </div>
          <div className={wrapStyles.form}>
            <div className={wrapStyles.form_section}>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Status</p>
                <div className={wrapStyles.svg}>
                  {isEditable && editField === 'edit_status' ? (
                    <Formik
                      initialValues={{
                        status: editContract?.status
                          ? editContract.status.charAt(0).toUpperCase() + editContract.status.slice(1)
                          : '',
                      }}
                      onSubmit={values => handleSubmit('status', values.status)}
                    >
                      {({ setFieldValue }) => (
                        <Form className={styles.select}>
                          <FormikDropdown
                            name="status"
                            placeholder=""
                            className={styles.selectInput}
                            data={CONTRACTS_STATUSES_EDIT || []}
                            defaultValue={undefined}
                            handleChange={(value: any) => {
                              handleSubmit('status', formattedStatus(value));
                            }}
                            textField="name"
                            addFilter={true}
                            defaultOpen={true}
                            onSelect={(value: any) => {
                              if (editContract.status === value) {
                                handleSubmit('status', formattedStatus(value));
                              }
                            }}
                            onDropdownClose={() => {
                              setTimeout(() => {
                                if (editContract.status && editField === 'edit_status') {
                                  setEditField(prev => (prev === 'edit_status' ? '' : prev));
                                }
                              }, 250);
                            }}
                          />
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div
                      onClick={() => {
                        setEditField('edit_status');
                      }}
                    >
                      {editContract?.status
                        ? editContract.status.charAt(0).toUpperCase() + editContract.status.slice(1)
                        : '-'}
                    </div>
                  )}
                </div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Start Date</p>
                {isEditable && editField === 'edit_startDate' ? (
                  <Formik
                    initialValues={{
                      startDate: format(new Date(editContract?.startDate), 'yyyy/MM/dd') || '',
                    }}
                    onSubmit={values => handleSubmit('startDate', values.startDate)}
                  >
                    {({ setFieldValue, values }) => (
                      <Form>
                        <FormikDatePicker
                          name="startDate"
                          dateFormat={DATE_PICKER.dateFormatToYear}
                          placeholderText="yyyy/mm/dd"
                          className={styles.inputShow}
                          autoFocus
                          onBlur={e => {
                            handleSubmit('startDate', format(new Date(e.target.value), 'yyyy-MM-dd'));
                          }}
                          onCalendarClose={e => {
                            handleSubmit('startDate', format(new Date(values.startDate), 'yyyy-MM-dd'));
                          }}
                          maxDate={editContract?.endDate ? new Date(editContract?.endDate) : ''}
                          showYearDropdown
                        />
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div
                    onClick={() => {
                      setEditField('edit_startDate');
                    }}
                  >
                    {editContract?.startDate ? editContract?.startDate.replace(/-/g, '/') : '-'}
                  </div>
                )}
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">End Date</p>
                {isEditable && editField === 'edit_endDate' ? (
                  <Formik
                    initialValues={{
                      endDate: format(new Date(editContract?.endDate), 'yyyy/MM/dd') || '',
                    }}
                    onSubmit={values => handleSubmit('endDate', values.endDate)}
                  >
                    {({ setFieldValue, values }) => (
                      <Form>
                        <FormikDatePicker
                          name="endDate"
                          dateFormat={DATE_PICKER.dateFormatToYear}
                          placeholderText=""
                          className={styles.inputShow}
                          autoFocus
                          onBlur={e => {
                            handleSubmit('endDate', format(new Date(e.target.value), 'yyyy-MM-dd'));
                          }}
                          onCalendarClose={e => {
                            handleSubmit('endDate', format(new Date(values.endDate), 'yyyy-MM-dd'));
                          }}
                          minDate={editContract?.startDate ? new Date(editContract?.startDate) : ''}
                          showYearDropdown
                        />
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div
                    onClick={() => {
                      setEditField('edit_endDate');
                    }}
                  >
                    {editContract?.endDate ? editContract?.endDate.replace(/-/g, '/') : '-'}
                  </div>
                )}
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Legal Entity</p>
                {isEditable && editField === 'edit_legalEntity' ? (
                  <Formik
                    initialValues={{ legalEntity: editContract?.legalEntity || '' }}
                    onSubmit={values => handleSubmit('legalEntity', values.legalEntity)}
                  >
                    {({ setFieldValue, values }) => (
                      <Form className={styles.form}>
                        <FormikInput
                          name="legalEntity"
                          placeholder=""
                          className={styles.inputShow}
                          onSubmit={e => setFieldValue('legalEntity', editContract.legalEntity)}
                          onBlur={e => {
                            handleSubmit('legalEntity', editContract.legalEntity);
                          }}
                          onHandleKeyDown={e => {
                            if (e.code === 'Enter') {
                              handleSubmit('legalEntity', e.target.value);
                            }
                            if (e.code === 'Escape') {
                              handleSubmit('legalEntity', editContract.legalEntity);
                            }
                          }}
                          autoFocus
                        />
                        <button
                          type="submit"
                          onMouseDown={e => e.preventDefault()}
                          onClick={e => {
                            handleSubmit('legalEntity', values.legalEntity);
                          }}
                        >
                          {checkIcon}
                        </button>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div
                    className={wrapStyles.maxWight}
                    onClick={() => {
                      setEditField('edit_legalEntity');
                    }}
                  >
                    {editContract?.legalEntity ? editContract?.legalEntity : '-'}
                  </div>
                )}
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Project</p>
                <div className={wrapStyles.svg}>
                  {isEditable && editField === 'edit_project' ? (
                    <Formik
                      initialValues={{ projectId: editContract?.project || '' }}
                      onSubmit={values => handleSubmit('project', values)}
                    >
                      {({ setFieldValue }) => (
                        <Form className={styles.select}>
                          <FormikDropdown
                            name="projectId"
                            placeholder=""
                            data={projects || []}
                            className={styles.selectInput}
                            defaultValue={undefined}
                            onDropdownSearch={onProjectSearch}
                            handleChange={async  (value: Project) => {
                              setEditContract(prevContract => ({
                                ...prevContract,
                                projectInitRequest: null,
                                project: value,
                              }));

                              const projectValues = {
                                name: value.name || '',
                                projectKey: value.projectKey || '',
                              };

                              try {
                                const newProjectAction = await dispatch(createBusinessProject({ values: projectValues, callback: () => {} }));
                                const newProject = await (newProjectAction as any).payload;       
                                await setSelectedProject(newProject);
                                await handleSubmit('project', newProject);
                              } catch (error) {
                                console.error(error);
                              }
                            }}
                            textField="name"
                            addFilter={true}
                            defaultOpen={true}
                            clearValue
                            clear={() => {
                              handleSubmit('project', null);
                              setSelectedProject(null);
                            }}
                            onDropdownClose={() => {
                              setTimeout(() => {
                                if (editContract && editField === 'edit_project') {
                                  dispatch(getProjectsList({ page: 1, size: 250 }));
                                  setEditField(prev => (prev === 'edit_project' ? '' : prev));
                                }
                              }, 250);
                            }}
                          />
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div
                      onClick={() => {
                        setEditField('edit_project');
                      }}
                    >
                      {editContract?.project?.name ? editContract?.project?.name : '-'}
                    </div>
                  )}
                </div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Linked PIR</p>
                {editContract?.projectInitRequest && (
                  <a
                    href={editContract?.projectInitRequest.issueLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={wrapStyles.pirButton}
                  >
                    PIR Link
                  </a>
                )}
                {!editContract?.projectInitRequest && (
                  <button className={wrapStyles.pirButton}>Create Linked PIR</button>
                )}
                <div className={wrapStyles.svg} style={{ marginLeft: 16 }}>
                  {isEditable && editField === 'edit_projectInitRequest' ? (
                    <Formik
                      initialValues={{ projectInitRequestKey: editContract?.projectInitRequest || '' }}
                      onSubmit={values => handleSubmit('projectInitRequest', values)}
                    >
                      {({ setFieldValue }) => (
                        <Form className={styles.selectPIR}>
                          <FormikDropdown
                            name="projectInitRequestKey"
                            placeholder=""
                            className={`${styles.selectInput} ${styles.selectPIR}`}
                            data={projectInitRequests || []}
                            defaultValue={undefined}
                            onDropdownSearch={onPIRSearch}
                            handleChange={(value: any) => {
                              handleSubmit('projectInitRequest', value);
                            }}
                            textField={(item: any) => getFullPIRName(item)}
                            addFilter={true}
                            defaultOpen={true}
                            onSelect={(value: any) => {
                              if (editContract.projectInitRequest?.issueKey === value.issueKey) {
                                handleSubmit('projectInitRequest', value);
                              }
                            }}
                            clearValue
                            clear={() => {
                              handleSubmit('projectInitRequest', null);
                            }}
                            onDropdownClose={() => {
                              setTimeout(() => {
                                if (editContract.projectInitRequest && editField === 'edit_projectInitRequest') {
                                  dispatch(getProjectInitRequests({ page: 1, size: 250 }));
                                  setEditField(prev => (prev === 'edit_projectInitRequest' ? '' : prev));
                                }
                              }, 250);
                            }}
                          />
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div
                      onClick={() => {
                        setEditField('edit_projectInitRequest');
                      }}
                      className={styles.pir}
                    >
                      {editContract?.projectInitRequest ? editContract?.projectInitRequest?.issueKey : '-'}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={wrapStyles.form_section}>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">SignNow Document ID</p>
                {isEditable && editField === 'edit_signNowDocumentId' ? (
                  <Formik
                    initialValues={{ signNowDocumentId: editContract?.signNowDocumentId || '' }}
                    onSubmit={values => handleSubmit('signNowDocumentId', values.signNowDocumentId)}
                  >
                    {({ setFieldValue, values }) => (
                      <Form className={styles.form}>
                        <FormikInput
                          name="signNowDocumentId"
                          placeholder=""
                          className={styles.inputShow}
                          onSubmit={e => setFieldValue('signNowDocumentId', editContract.signNowDocumentId)}
                          onBlur={e => {
                            handleSubmit('signNowDocumentId', editContract.signNowDocumentId);
                          }}
                          onHandleKeyDown={e => {
                            if (e.code === 'Enter') {
                              handleSubmit('signNowDocumentId', e.target.value);
                            }
                            if (e.code === 'Escape') {
                              handleSubmit('signNowDocumentId', editContract.signNowDocumentId);
                            }
                          }}
                          autoFocus
                        />
                        <button
                          type="submit"
                          onMouseDown={e => e.preventDefault()}
                          onClick={e => {
                            handleSubmit('signNowDocumentId', values.signNowDocumentId);
                          }}
                        >
                          {checkIcon}
                        </button>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div
                    onClick={() => {
                      setEditField('edit_signNowDocumentId');
                    }}
                  >
                    {editContract?.signNowDocumentId ? editContract?.signNowDocumentId : '-'}
                  </div>
                )}
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Pipeline</p>
                <div className={wrapStyles.svg}>
                  {isEditable && editField === 'edit_pipeline' ? (
                    <Formik
                      initialValues={{ pipelineId: editContract?.pipeline || '' }}
                      onSubmit={values => handleSubmit('pipeline', values)}
                    >
                      {({ setFieldValue }) => (
                        <Form className={styles.select}>
                          <FormikDropdown
                            name="pipelineId"
                            placeholder=""
                            className={styles.selectInput}
                            data={pipelines || []}
                            defaultValue={undefined}
                            onDropdownSearch={onPipelineSearch}
                            handleChange={(value: any) => {
                              handleSubmit('pipeline', value);
                            }}
                            textField="name"
                            addFilter={true}
                            defaultOpen={true}
                            onSelect={(value: any) => {
                              if (editContract.pipeline?.id === value.id) {
                                handleSubmit('pipeline', value);
                              }
                            }}
                            clearValue
                            clear={() => {
                              handleSubmit('pipeline', null);
                            }}
                            onDropdownClose={() => {
                              setTimeout(() => {
                                if (editField === 'edit_pipeline') {
                                  setEditField(prev => (prev === 'edit_pipeline' ? '' : prev));
                                }
                              }, 250);
                            }}
                          />
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div
                      onClick={() => {
                        setEditField('edit_pipeline');
                      }}
                    >
                      {editContract?.pipeline ? editContract?.pipeline?.name : '-'}
                    </div>
                  )}
                </div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Engagement Model</p>
                <div className={wrapStyles.svg}>
                  {isEditable && editField === 'edit_model' ? (
                    <Formik
                      initialValues={{ engagementModelId: editContract?.engagementModel || '' }}
                      onSubmit={values => handleSubmit('engagementModel', values)}
                    >
                      {({ setFieldValue }) => (
                        <Form className={styles.select}>
                          <FormikDropdown
                            name="engagementModelId"
                            placeholder=""
                            className={styles.selectInput}
                            data={models || []}
                            defaultValue={undefined}
                            onDropdownSearch={onModelsSearch}
                            handleChange={(value: any) => {
                              handleSubmit('engagementModel', value);
                            }}
                            textField="name"
                            addFilter={true}
                            defaultOpen={true}
                            onSelect={(value: any) => {
                              if (editContract.engagementModel?.id === value.id) {
                                handleSubmit('engagementModel', value);
                              }
                            }}
                            clearValue
                            clear={() => {
                              handleSubmit('engagementModel', null);
                            }}
                            onDropdownClose={() => {
                              setTimeout(() => {
                                if (editField === 'edit_model') {
                                  setEditField(prev => (prev === 'edit_model' ? '' : prev));
                                }
                              }, 250);
                            }}
                          />
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div
                      onClick={() => {
                        setEditField('edit_model');
                      }}
                    >
                      {editContract?.engagementModel ? editContract?.engagementModel?.name : '-'}
                    </div>
                  )}
                </div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Invoice Due Date</p>
                {isEditable && editField === 'edit_invoiceDueDate' ? (
                  <Formik
                    initialValues={{
                      invoiceDueDate: editContract.invoiceDueDate
                        ? format(new Date(editContract.invoiceDueDate), 'yyyy/MM/dd')
                        : null,
                    }}
                    onSubmit={values => handleSubmit('invoiceDueDate', values.invoiceDueDate)}
                  >
                    {({ setFieldValue, values }) => (
                      <Form className={styles.iconPosition}>
                        <FormikDatePicker
                          name="invoiceDueDate"
                          dateFormat={DATE_PICKER.dateFormatToYear}
                          placeholderText=""
                          className={styles.inputShow}
                          autoFocus
                          onBlur={e => {
                            if (e.target.value === '') {
                              handleSubmit('invoiceDueDate', null);
                            } else {
                              handleSubmit('invoiceDueDate', format(new Date(e.target.value), 'yyyy-MM-dd'));
                            }
                          }}
                          onCalendarClose={() => {
                            setTimeout(() => {
                              if (values.invoiceDueDate) {
                                handleSubmit('invoiceDueDate', format(new Date(values.invoiceDueDate), 'yyyy-MM-dd'));
                              }
                            }, 250);
                          }}
                          showYearDropdown
                          clearValue
                          clear={() => {
                            handleSubmit('invoiceDueDate', null);
                          }}
                        />
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div
                    onClick={() => {
                      setEditField('edit_invoiceDueDate');
                    }}
                  >
                    {editContract?.invoiceDueDate ? editContract?.invoiceDueDate.replace(/-/g, '/') : '-'}
                  </div>
                )}
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Payment Basis</p>
                <div className={wrapStyles.svg}>
                  {isEditable && editField === 'edit_paymentBasis' ? (
                    <Formik
                      initialValues={{ paymentBasisId: editContract?.paymentBasis || '' }}
                      onSubmit={values => handleSubmit('paymentBasis', values)}
                    >
                      {({ setFieldValue }) => (
                        <Form className={styles.select}>
                          <FormikDropdown
                            name="paymentBasisId"
                            placeholder=""
                            className={styles.selectInput}
                            data={payments || []}
                            defaultValue={undefined}
                            onDropdownSearch={onPaymentsSearch}
                            handleChange={(value: any) => {
                              handleSubmit('paymentBasis', value);
                            }}
                            textField="name"
                            addFilter={true}
                            defaultOpen={true}
                            onSelect={(value: any) => {
                              if (editContract.paymentBasis?.id === value.id) {
                                handleSubmit('paymentBasis', value);
                              }
                            }}
                            clearValue
                            clear={() => {
                              handleSubmit('paymentBasis', null);
                            }}
                            onDropdownClose={() => {
                              setTimeout(() => {
                                if (editField === 'edit_paymentBasis') {
                                  setEditField(prev => (prev === 'edit_paymentBasis' ? '' : prev));
                                }
                              }, 250);
                            }}
                          />
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div
                      onClick={() => {
                        setEditField('edit_paymentBasis');
                      }}
                    >
                      {editContract?.paymentBasis ? editContract?.paymentBasis?.name : '-'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {services?.length ? (
            <ServicesTable
              data={services}
              callback={() => getContractServices()}
              totals={totals}
              isEditable={isEditable}
              editContract={editContract}
            />
          ) : servicesLoading ? (
            <Loader />
          ) : (
            <div></div>
          )}
        </CardBody>
      </Card>
      {openCreateContractModal && (
        <AddServiceModal
          onClose={() => setOpenCreateContractModal(false)}
          callback={() => getContractServices()}
          setOpenModal={setOpenCreateContractModal}
        />
      )}
    </>
  );
};
export default ContractDetails;