import { createAsyncThunk } from '@reduxjs/toolkit';
import businessPaymentService from 'services/businessPaymentService';
import { RequestParams } from 'utils/mapParams';

export const getBusinessPaymentList = createAsyncThunk(
  'businessPayment/getBusinessPaymentList',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const res = await businessPaymentService.getBusinessPaymentList(params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
