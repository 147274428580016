import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { DropdownList } from 'react-widgets';
import TeamFilter from 'common/TeamFilter/TeamFilter';
import ProjectFilter from 'common/ProjectFilter/ProjectFilter';

import { getEmployeeListThunk } from '../../store/employees';
import { AppState } from 'store/configureStore';
import EmployeeModel from 'models/employee.model';
import { WorklogIssuesInputs } from 'models/interfaces/vpDashboard.interface';

import { useQuery } from 'hooks/queryHook';
import { Role } from '../../constants/roles';
import { formatEmployees } from '../../utils/formatDatas';
import { getFullName } from '../../helpers/companyStructureHeplers';
import { currentDay } from '../../helpers/formatTime';

import styles from '../tableStyles.module.scss';

const VpDashboardInputs: FC<WorklogIssuesInputs> = ({
  error,
  setTeamValue,
  onChangeEmployee,
  role,
  setProjectSearch,
}) => {
  const dispatch = useDispatch();

  const { employees: tempEmployees } = useSelector((state: AppState) => state.employeesReducer);
  const employees = tempEmployees && formatEmployees(tempEmployees);

  const { employee, team, setProjectKey } = useQuery();

  useEffect(() => {
    role !== Role.user && dispatch(getEmployeeListThunk({ date: currentDay() }));
  }, [dispatch, role]);

  const selectedEmployee = employees?.find((item: EmployeeModel) => item.id === Number(employee));

  return (
    <div className="bp-header">
      <div className="filters-block">
        <ProjectFilter setFilterValue={setProjectKey} />

        {role === Role.vp && <TeamFilter team={team} setTeam={setTeamValue} setSearchData={setProjectSearch} />}

        {role !== Role.user && (
          <div className="dropdown-filter">
            <span className="label-wrapper">Employee</span>
            <DropdownList
              containerClassName={cx(styles.inputsField, styles.dropdown)}
              data={employees || []}
              value={selectedEmployee ? selectedEmployee : ''}
              placeholder="Filter By Employee"
              filter="contains"
              textField={(item: any) => getFullName(item)}
              onChange={onChangeEmployee}
              busy={!employees && !error}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VpDashboardInputs;
