import React, { FC, MutableRefObject, useRef, useState } from 'react';
import { useField } from 'formik';
import { FieldConfig } from 'formik/dist/Field';
import DatePicker from 'react-datepicker';
import { Tooltip } from 'reactstrap';
import { useClickOutside } from 'hooks/useClickOutside';
import { closeIcon } from 'constants/icons';

import stylesModal from '../../../components/modal.module.scss';
import styles from '../Formik.module.scss';

type FormikDatePickerT = {
  dateFormat: string;
  placeholderText?: string;
  label?: string;
  className?: string;
  errorClassName?: string;
  showYearDropdown?: boolean;
  minDate?: Date | string | undefined;
  maxDate?: Date | string;
  disabled?: boolean;
  onSubmit?: () => void;
  onChange?: (value: Date) => void;
  onBlur?: (e?: any) => void;
  autoFocus?: boolean;
  readOnly?: boolean;
  clearValue?: boolean;
  closeIconClassName?: string;
  clear?: (e?: any) => void;
  setCurrentlyEditing?: (value: string) => void;
  fieldRef?: MutableRefObject<null>;
  fixedHeight?: boolean;
  onCalendarClose?: (e?: any) => void;
} & FieldConfig;

const FormikDatePicker: FC<FormikDatePickerT> = ({
  className,
  errorClassName,
  label,
  onSubmit,
  onChange,
  onBlur,
  readOnly,
  autoFocus,
  clearValue,
  clear,
  closeIconClassName,
  setCurrentlyEditing,
  fieldRef,
  onCalendarClose,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { error, value, touched } = meta;

  const elementRef = useRef(null);
  const [openTooltip, setOpenTooltip] = useState(false);

  if (onSubmit && setCurrentlyEditing) {
    const handleSubmit = () => {
      if (field.value != meta.initialValue) {
        onSubmit();
      }
      setCurrentlyEditing('');
    };

    useClickOutside(elementRef, handleSubmit, fieldRef);
  }

  const selectedDate = value ? (value instanceof Date ? value : new Date(value)) : null;

  const handleChange = (value: Date) => {
    if (onChange) {
      onChange(value);
    }
    helpers.setValue(value);
  };

  const handleBlur = (e?: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
    if (onBlur && e?.target) {
      onBlur(e);
    }
  }, 250);
  };

  return (
    <div
      className={className}
      style={{ position: 'relative' }}
      ref={elementRef}
      onMouseEnter={() => setOpenTooltip(true)}
      onMouseLeave={() => setOpenTooltip(false)}
    >
      {label && <p className="label-wrapper">{label}</p>}
      <DatePicker
        {...props}
        {...field}
        selected={selectedDate}
        onChange={handleChange}
        className={error && touched ? (errorClassName ? errorClassName : stylesModal.error) : ''}
        onBlur={handleBlur}
        autoFocus={autoFocus}
        onCalendarClose={onCalendarClose}
        readOnly={readOnly}
      />
      {clearValue && (
        <div
          className={closeIconClassName ? closeIconClassName : styles.closeIcon}
          onClick={() => {
            helpers.setValue(null);
            if (clear) {
              clear();
            }
          }}
        >
          {closeIcon}
        </div>
      )}
      {error && touched ? (
        errorClassName ? (
          <Tooltip placement="bottom" isOpen={openTooltip} target={elementRef}>
            <div>{error}</div>
          </Tooltip>
        ) : (
          <div className={styles.dateError}>{error}</div>
        )
      ) : null}
    </div>
  );
};

export default FormikDatePicker;
