import React, { Dispatch, RefObject, SetStateAction, useCallback, useState, useRef } from 'react';
import { useField } from 'formik';
import { FieldConfig } from 'formik/dist/Field';
import { DropdownList } from 'react-widgets';

import { useClickOutside } from 'hooks/useClickOutside';
import { closeIcon } from 'constants/icons';

import stylesModal from '../../../components/modal.module.scss';
import styles from '../Formik.module.scss';

type FormikDropdownT<T> = {
  placeholder?: string;
  label?: string;
  className?: string;
  closeIconClassName?: string;
  data: T[];
  textField?:
    | string
    | ((item: { firstName: string; lastName: string }) => string)
    | ((item: { summary: string; issueKey: string }) => string);
  defaultValue?: T;
  dataKey?: string;
  busy?: boolean;
  disabled?: boolean;
  containerClassName?: string;
  dropUp?: boolean;
  addFilter?: boolean;
  readOnly?: boolean;
  handleSubmit?: () => void;
  handleChange?: (value: T) => void;
  onSelect?: (e?: any) => void;
  onDropdownSearch?: (event: KeyboardEvent) => void;
  setEditMode?: Dispatch<SetStateAction<number | boolean>>;
  clearValue?: boolean;
  defaultOpen?: boolean;
  onBlur?: boolean;
  elementRef?: RefObject<HTMLDivElement> | null;
  onDropdownClose?: (e?: any) => void;
  clear?: (e?: any) => void;
} & FieldConfig;

const FormikDropdown = <T,>({
  className,
  closeIconClassName,
  dataKey,
  addFilter,
  label,
  handleSubmit,
  handleChange,
  onSelect,
  readOnly,
  onDropdownSearch,
  setEditMode,
  clearValue,
  defaultOpen,
  onBlur,
  elementRef,
  onDropdownClose,
  clear,
  ...props
}: FormikDropdownT<T>) => {
  const [field, meta, helpers] = useField(props.name);
  const { error, touched } = meta;
  const [isOpen, setIsOpen] = useState(defaultOpen ? true : false);

  const fieldRef = useRef<HTMLDivElement>(null);
  
    if (handleSubmit && setEditMode) {
      const onInputBlur = useCallback(() => {
        field.value !== meta.initialValue ? handleSubmit() : setEditMode(false);
      }, [field.value, meta.initialValue]);

      useClickOutside(fieldRef, onInputBlur, elementRef);
    }

  const onChange = (value: any) => {
    if (handleChange) {
      handleChange(value);
    }
    if (dataKey) {
      helpers.setValue(value[dataKey]);
    } else {
      helpers.setValue(value);
    }
  };

  const handleDropdownToggle = (open: boolean) => {
    if (!open) {
      if (onDropdownClose) {
        onDropdownClose();
      }
    }
    setIsOpen(open);
  };

  return (
    <div ref={fieldRef} className={className} style={{ position: 'relative' }}>
      {label && <p>{label}</p>}

      {dataKey ? (
        <DropdownList
          {...props}
          filter={addFilter ? 'contains' : false}
          value={props?.data.find((el: any) => {
            return el[dataKey] == field.value;
          })}
          onChange={onChange}
          onKeyDown={onDropdownSearch}
          defaultOpen={defaultOpen}
          onSelect={onSelect}
          open={isOpen}
          onToggle={handleDropdownToggle}
          readOnly={readOnly}
        />
      ) : (
        <DropdownList
          filter={addFilter ? 'contains' : false}
          {...props}
          {...field}
          onChange={onChange}
          containerClassName={error && touched ? stylesModal.fieldError : ''}
          onKeyDown={onDropdownSearch}
          defaultOpen={defaultOpen}
          onSelect={onSelect}
          open={isOpen}
          onToggle={handleDropdownToggle}
          readOnly={readOnly}
        />
      )}

      {clearValue && (
        <div
          className={closeIconClassName ? closeIconClassName : styles.closeIcon}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            helpers.setValue(null);
            if (clear) {
              clear();
            }
          }}
        >
          {closeIcon}
        </div>
      )}

      {error && touched ? <div className={styles.dropdownError}>{error}</div> : null}
    </div>
  );
};

export default FormikDropdown;
