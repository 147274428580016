import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';

import FormikDropdown from 'common/Formik/FormikDropdown';
import FormikInput from 'common/Formik/FormikInput';
import FormikDatePicker from 'common/Formik/FormikDatePicker';
import Drawer from './Drawer';
import ActionButtons from './ActionButtons';

import { getBusinessPipelinesList } from 'store/businessPipelines/businessPipelines.thunk';
import { getEngagementModelList } from 'store/engagementModel/engagementModel.thunk';
import { getBusinessPaymentList } from 'store/businessPayments/businessPayments.thunk';
import { getProjectInitRequests } from 'store/projectInitRequests/projectInitRequests.thunk';
import { createService } from 'store/services/services.thunk';
import { AppState } from 'store/configureStore';

import { DATE_PICKER, SERVICE_TYPES, SERVICES_CURRENCY, QUANTITY } from 'constants/common';
import { BillingType } from 'models/interfaces/services.interface';
import { AddServiceModalProps, AddServiceInitialValues } from 'models/interfaces/services.interface';

import styles from '../../../components/modal.module.scss';
import drawerStyles from './styles.module.scss';

const ADD_SERVICE_SCHEMA = Yup.object({
  serviceType: Yup.string().required('Field required'),
  name: Yup.string().min(2, 'Less than 2 characters').max(128, 'Service Name is too long').required('Field required'),
  startDate: Yup.string().nullable().required('Field required'),
  endDate: Yup.string().when('repeat', {
    is: (repeat: boolean) => !repeat,
    then: Yup.string().required('Field required').nullable(),
    otherwise: Yup.string().nullable(),
  }),
  currency: Yup.object().required('Field required'),
  amount: Yup.string()
    .test('min-length', 'Field required', value => typeof value === 'string' && value.length >= 0)
    .test('max-length', 'Amount is too long', value => typeof value === 'string' && value.length <= 128)
    .matches(/^\d{1,128}(\.\d+)?$/, 'Invalid amount format')
    .required('Field required'),
  pipelineId: Yup.object().nullable().optional(),
  engagementModelId: Yup.object().nullable().optional(),
  billingTypeId: Yup.object().nullable().required('Field required'),
  paymentBasisId: Yup.object().nullable().optional(),
  quantity: Yup.number().nullable().required('Field required'),
  discount: Yup.number()
    .nullable()
    .typeError('Invalid discount format')
    .min(0, 'Discount cannot be negative')
    .max(100, 'Discount cannot be more than 100')
    .test(
      'decimal-places',
      'Discount can have up to 2 decimal places',
      value => value == null || /^\d+(\.\d{1,2})?$/.test(value.toString())
    )
    .optional(),
});



const AddServiceModal = ({ onClose, callback, setOpenModal }: AddServiceModalProps) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { contract, loading } = useSelector((state: AppState) => state.contractsReducer);
  const { pipelines } = useSelector((state: AppState) => state.businessPipelinesReducer);
  const { models } = useSelector((state: AppState) => state.engagementModelsReducer);
  const { payments } = useSelector((state: AppState) => state.businessPaymentsReducer);
  const { billingTypes } = useSelector((state: AppState) => state.businessBillingTypesReducer);
  const { items: services, loading: servicesLoading } = useSelector((state: AppState) => state.servicesReducer);

  const [serviceTypePattern, setServiceTypePattern] = useState<string | null>('Service Type');
  const [pipelinePattern, setPipelinePattern] = useState<string | null>(contract.pipeline ? `-${contract.pipeline.name}` : '');
  const [engagementModelPattern, setEngagementModelPattern] = useState<string | null>(
    contract.engagementModel ? `-${contract.engagementModel.name}` : ''
  );
  const [servicesLength, setServicesLength] = useState<number | null>(services.length + 1);

  useEffect(() => {
    setPipelinePattern(contract.pipeline ? `-${contract.pipeline.name}` : '');
    setEngagementModelPattern(contract.engagementModel ? `-${contract.engagementModel.name}` : '');
  }, [contract]);

  useEffect(() => {
    dispatch(getProjectInitRequests({ page: 1, size: 250 }));
  }, []);

  const onPipelinesSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      dispatch(getBusinessPipelinesList({ page: 1, size: 50 }));
    }, 250),
    []
  );

  const onModelsSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      dispatch(getEngagementModelList({ page: 1, size: 50 }));
    }, 250),
    []
  );

  const onPaymentsSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      dispatch(getBusinessPaymentList({ page: 1, size: 50 }));
    }, 250),
    []
  );

  const initialValues: AddServiceInitialValues = {
    serviceType: '',
    name: contract ? `${serviceTypePattern}${pipelinePattern}${engagementModelPattern}-${servicesLength}` : '',
    startDate: '',
    endDate: '',
    currency: '',
    amount: '',
    pipelineId: contract.pipeline ? { id: contract.pipeline.id, name: contract.pipeline.name } : null,
    engagementModelId: contract.engagementModel
      ? { id: contract.engagementModel.id, name: contract.engagementModel.name }
      : null,
    billingTypeId: 0,
    paymentBasisId: contract.paymentBasis ? { id: contract.paymentBasis.id, name: contract.paymentBasis.name } : null,
    quantity: null,
    discount: null,
  };

  const onSubmit = (values: AddServiceInitialValues) => {
    const formattedValues = {
      pipelineId: values.pipelineId?.id || null,
      engagementModelId: values.engagementModelId?.id || null,
      paymentBasisId: values.paymentBasisId?.id || null,
      billingTypeId: (values.billingTypeId as BillingType)?.id || 0,
      amount: Number(values.amount),
      currency: String((values.currency as BillingType)?.name || ''),
      serviceType: values.serviceType,
      name: values.name,
      quantity: values.quantity,
      discount: Number(values.discount),
      startDate: values.startDate ? format(new Date(values.startDate), 'yyyy-MM-dd') : '',
      endDate: values.endDate ? format(new Date(values.endDate), 'yyyy-MM-dd') : '',
      contractId: Number(id),
    };

    dispatch(createService({ values: formattedValues, callback }));
    setOpenModal(false);
  };

  return createPortal(
    <Formik
      initialValues={initialValues}
      validationSchema={ADD_SERVICE_SCHEMA}
      validateOnBlur={true}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, validateForm, setFieldTouched, isValid, dirty }) => (
        <Form>
          <Drawer
            className={cx(drawerStyles.drawer, drawerStyles.active, 'text-left')}
            title="Add Service"
            onClose={onClose}
          >
            <div className={styles.inputWrap}>
              <div className="dropdown-filter">
                <div className={cx(drawerStyles.gap, 'd-flex')}>
                  <p className="label-wrapper">Service Type</p>
                  <p className={styles.required}>*</p>
                </div>
                <FormikDropdown
                  name="serviceType"
                  placeholder="Service Type"
                  className={styles.drop}
                  data={SERVICE_TYPES || []}
                  handleChange={(value: any) => {
                    setServiceTypePattern(`${value}`);
                    setFieldValue('name', `${value}${pipelinePattern}${engagementModelPattern}-${servicesLength}`);
                  }}
                  addFilter={true}
                  onDropdownClose={() => {
                    setTimeout(() => {
                      setFieldTouched('serviceType', true);
                    }, 0);
                  }}
                />
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Start Date</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikDatePicker
                    name="startDate"
                    dateFormat={DATE_PICKER.dateFormatMonthAndDay}
                    placeholderText="yyyy/mm/dd"
                    className={styles.input}
                    minDate={contract?.startDate ? new Date(contract?.startDate) : ''}
                    maxDate={contract?.endDate ? (values.endDate ? values.endDate : new Date(contract?.endDate)) : ''}
                    showYearDropdown
                    onBlur={() => setFieldTouched('startDate', true)}
                  />
                </div>

                <div>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">End Date</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikDatePicker
                    name="endDate"
                    dateFormat={DATE_PICKER.dateFormatMonthAndDay}
                    placeholderText="yyyy/mm/dd"
                    className={styles.input}
                    minDate={contract?.startDate ? (values.startDate ? values.startDate : new Date(contract?.startDate)) : ''}
                    maxDate={contract?.endDate ? new Date(contract?.endDate) : ''}
                    showYearDropdown
                    onBlur={() => setFieldTouched('endDate', true)}
                  />
                </div>
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div className="dropdown-filter" style={{ maxWidth: '181px' }}>
                  <p className="label-wrapper">Pipeline</p>
                  <FormikDropdown
                    name="pipelineId"
                    className={styles.clearIcon}
                    placeholder="Pipeline"
                    data={pipelines || []}
                    handleChange={(value: any) => {
                      setPipelinePattern(`-${value.name}`);
                      setFieldValue(
                        'name',
                        `${serviceTypePattern}-${value.name}${engagementModelPattern}-${servicesLength}`
                      );
                    }}
                    onDropdownSearch={onPipelinesSearch}
                    textField="name"
                    addFilter={true}
                    clearValue
                    clear={() => {
                      setPipelinePattern('');
                      setFieldValue('name', `${serviceTypePattern}${engagementModelPattern}-${servicesLength}`);
                    }}
                  />
                </div>

                <div className="dropdown-filter" style={{ maxWidth: '181px' }}>
                  <p className="label-wrapper">Engagement Model</p>
                  <FormikDropdown
                    name="engagementModelId"
                    className={styles.clearIcon}
                    placeholder="Engagement Model"
                    data={models || []}
                    handleChange={(value: any) => {
                      setEngagementModelPattern(`-${value.name}`);
                      setFieldValue('name', `${serviceTypePattern}${pipelinePattern}-${value.name}-${servicesLength}`);
                    }}
                    onDropdownSearch={onModelsSearch}
                    textField="name"
                    addFilter={true}
                    clearValue
                    clear={() => {
                      setEngagementModelPattern('');
                      setFieldValue('name', `${serviceTypePattern}${pipelinePattern}-${servicesLength}`);
                    }}
                  />
                </div>
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div className="dropdown-filter" style={{ maxWidth: '181px' }}>
                  <p className="label-wrapper">Payment Basis</p>
                  <FormikDropdown
                    name="paymentBasisId"
                    className={styles.clearIcon}
                    placeholder="Payment Basis"
                    data={payments || []}
                    defaultValue=""
                    onDropdownSearch={onPaymentsSearch}
                    textField="name"
                    addFilter={true}
                    clearValue
                  />
                </div>

                <div className="dropdown-filter" style={{ minWidth: '181px' }}>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Billing Type</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikDropdown
                    name="billingTypeId"
                    placeholder="Billing Type"
                    data={billingTypes || []}
                    textField="name"
                    onDropdownClose={() => {
                      setTimeout(() => {
                        setFieldTouched('billingTypeId', true);
                      }, 0);
                    }}
                  />
                </div>
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div className="dropdown-filter" style={{ minWidth: '181px' }}>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Currency</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikDropdown
                    name="currency"
                    placeholder="Currency"
                    data={SERVICES_CURRENCY || []}
                    textField="name"
                    onDropdownClose={() => {
                      setTimeout(() => {
                        setFieldTouched('currency', true);
                      }, 0);
                    }}
                  />
                </div>

                <div className="dropdown-filter" style={{ minWidth: '181px' }}>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Amount</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikInput
                    name="amount"
                    onBlur={e => {
                      setFieldTouched('amount', true);
                    }}
                    placeholder="Amount"
                    className={styles.input}
                  />
                </div>
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div className="dropdown-filter" style={{ minWidth: '181px' }}>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Quantity</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikDropdown
                    name="quantity"
                    placeholder="Quantity"
                    data={QUANTITY || []}
                    textField="name"
                    onDropdownClose={() => {
                      setTimeout(() => {
                        setFieldTouched('quantity', true);
                      }, 0);
                    }}
                  />
                </div>

                <div className="dropdown-filter" style={{ minWidth: '181px' }}>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Discount, %</p>
                  </div>
                  <FormikInput
                    name="discount"
                    placeholder="Discount"
                    className={styles.input}
                    onBlur={e => {
                      setFieldTouched('discount', true);
                    }}
                  />
                </div>
              </div>

              <div>
                <div className={cx(drawerStyles.gap, 'd-flex')}>
                  <p className="label-wrapper">Service Name</p>
                  <p className={styles.required}>*</p>
                </div>
                <FormikInput
                  name="name"
                  onBlur={() => setFieldTouched('name', true)}
                  placeholder="Service Name"
                  className={styles.input}
                />
              </div>
            </div>

            <ActionButtons
              className={drawerStyles.buttonWrapper}
              cancelText="Cancel"
              submitText="Add"
              onCancel={onClose}
              isSubmitBtn
              disabled={!(isValid && dirty)}
            />
          </Drawer>
        </Form>
      )}
    </Formik>,
    document.body
  );
};

export default AddServiceModal;