import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import { DropdownList } from 'react-widgets';

import { AppState } from 'store/configureStore';
import { getEngagementModelList } from 'store/engagementModel/engagementModel.thunk';
import { useQuery } from 'hooks/queryHook';
import { formatEngagementModel } from 'utils/formatDatas';
import { EngagementModel } from 'models/interfaces/engagementModel.interface';

import styles from '../../components/tableStyles.module.scss';

interface EngagementModelFilterProps {
  setFilterValue: (value: string, search?: string) => void;
  searchValue?: string;
}

const EngagementModelFilter = ({ setFilterValue, searchValue }: EngagementModelFilterProps) => {
  const dispatch = useDispatch();

  const { models, loading } = useSelector((state: AppState) => state.engagementModelsReducer);
  const { engagementModelId } = useQuery();
  const [selectedEngagementModel, setSelectedEngagementModel] = useState<EngagementModel | null>(null);

  useEffect(() => {
    dispatch(getEngagementModelList({ page: 1, size: 50 }));
  }, []);

  useEffect(() => {
    if (models && engagementModelId) {
      const selected = models.find((model: EngagementModel) => model.id.toString() === engagementModelId);
      if (selected) {
        setSelectedEngagementModel(selected);
      }
    }
  }, [models, engagementModelId]);

  const onEngagementModelChange = (model: EngagementModel) => {
    setFilterValue(model.id, searchValue);
    setSelectedEngagementModel(model);
  };

  const onEngagementModelSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      const target = event.target as HTMLInputElement;
      dispatch(getEngagementModelList({ page: 1, size: 50, name: target.value }));
    }, 250),
    []
  );

  const engagementModelList = formatEngagementModel(models || []);

  return (
    <div className="dropdown-filter">
      <p className="label-wrapper">Engagement Model</p>
      <DropdownList
        containerClassName={cx(styles.inputsField, styles.dropdown)}
        value={selectedEngagementModel || ''}
        placeholder="All"
        data={engagementModelList}
        textField="name"
        filter="contains"
        onChange={onEngagementModelChange}
        onKeyDown={onEngagementModelSearch}
        busy={loading}
      />
    </div>
  );
};

export default EngagementModelFilter;
