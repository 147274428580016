import { createSlice } from '@reduxjs/toolkit';
import { getEngagementModelList } from './engagementModel.thunk';
import { EngagementModelInitialState } from 'models/interfaces/engagementModel.interface';

const initialState: EngagementModelInitialState = {
  models: null,
  loading: false,
  error: null,
};

const projectsSlice = createSlice({
  name: 'engagementModels',
  initialState,
  reducers: {},
  extraReducers: {
    [getEngagementModelList.pending.toString()]: state => {
      state.models = null;
      state.loading = true;
      state.error = null;
    },
    [getEngagementModelList.fulfilled.toString()]: (state, { payload }) => {
      state.models = payload.items;
      state.loading = false;
    },
    [getEngagementModelList.rejected.toString()]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export default projectsSlice.reducer;
